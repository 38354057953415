._2do {
  border: 2px dashed grey;
  padding: 10%;
  text-align: center;
  font-size: 5vw;
  margin-bottom: 3%;
}
.global--flower {
  position: fixed;
  max-width: 17.3%;
  z-index: 0;
}
.global--header  {
  padding-bottom: 10px;
  color: $domain-color;
  text-align: center;
  position: relative;
  z-index: 2;
  h3 {
    display: inline-block;
    font-size: 2.4rem;
    font-weight: 400;
    color: #666;
    padding: 1% 0;
    margin-bottom: 1%;
    font-weight: 500;
    letter-spacing: 0.3rem;
  }
}
.global--hd  {
  padding-bottom: 10px;
  text-align: center;
  position: relative;
  z-index: 2;
    width: 100%;
  h3 {    
    font-size: 3.2rem;
    padding: 3% 0;    
    font-family: $font-headers;
    font-weight: 800;
    @include max-breakpoint ($point2) {
        font-size: 2.4rem;
    }
  }
}
.global--center {
    text-align: center;
}

.__js_preload {
    opacity: .5;
}

.__js_inViewport {
    opacity: 0;
    top: -25px;
    position: relative;
    transition: all 390ms ease;    
}
.__js_onScreen {
    opacity: 1;
    top: 0px;
    position: relative;
    transition: all 390ms ease;
}
.global--offer {
    position: fixed;
    right: 0;
    top: 15vh;
    z-index: 10;
    text-indent: -9999px;
    width: 50px;
    height: 173px;
    background: url(images/static/offer.png);
    
    @include max-breakpoint ($point3) {
        background-size: contain;
        width: 40px;
        height: 145px;
    }
}

body .wp-player .npp-container.skinDefault .btnplaybig {
    //background: url(images/static/play.png) no-repeat center center;    
}
.global--fixed-button {
    position: fixed;
    top: calc(50% - 100px);
    right: 0;
    color: #fff;
    z-index: 3;
    font-family: $font-headers;
    padding-left: 50px;
    height: 262px;
    max-width: 0;
    transition: all 320ms ease 100ms;
    overflow: hidden;
    background: transparent url(images/static/bg-metka.jpg) 50px 0 no-repeat;
    > div {
        transform: rotate(-90deg) translateY(-100%) translateX(-50%);
        position: absolute;
        left: 0;
        width: 262px;
        background-color: $domain-color;
        border-radius: 13px 13px 0 0;
        height: 50px;
        line-height: 50px;
        text-align: center;
        margin-left: -56px;
        margin-top: -25px;
        cursor: pointer;
        font-size: 1.9rem;
        font-weight: 700px;
        letter-spacing: 0.2rem;
        transition: all 220ms ease 200ms;
    }
    &:hover {
        max-width: 300px;
        transition: all 220ms ease;
        > div {
            background-color: $alternate-color;
            transition: all 220ms ease;
        }
    }
    a {
        display: block;
        width: 114px;
        height: 34px;
        background: url(images/pobierz-a.png);
        text-indent: -9999px;
        overflow: hidden;
        margin: 169px 30px 0;
        &:nth-of-type(2) {
            background: url(images/pobierz-g.png);
            margin: 10px 30px;
        }
        
    }
    @include max-breakpoint($point2) {
         height: 200px;
        > div {
           font-size: 1.7rem; 
           width: 200px;
           transform: rotate(-90deg) translateY(-38%) translateX(-50%);
           margin-top: -25px;
        }
        a {
            margin: 109px 20px 0;
            &:nth-of-type(2) {
                margin: 10px 20px;
            }
        }
    }
}

.global--edition-hd {
    font-size: 30px;
    line-height: 2.04;
    text-align: center;
    padding: 2% 0;
    position: relative;
    margin: auto;
    &:after {
        display: block;
        position: absolute;
        content: '';
        height: 1px;
        background-color: #eeded9; //$alternate-color;
        top: 50%;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 0;
    }
    span {
        display: inline-block;
        padding: 0 22px;
        background-color: #fff;
        position: relative;
        z-index: 1;
    }
}

figure img {
    width: 100%;
    max-width: 100%!important;
    display: block;
    margin: 0 auto;
}
article.article figure {
    display: block;
    width: 100%;
    padding: 3% 0;
    p {
        padding: 0;
    }
}
article.article h2 + figure {
    margin-top: -4%;
}

figure figcaption {
    font-size: 14px;
    line-height: normal;
}
.video.youtube iframe {
    width: 100%;
    margin: 0 auto;
}
.video {
    padding: 3% 0;
}
.global---tag-hd {
    text-align: center;
    margin: 0 0 3%;
    background-color: $main-color;
    font-family: $font-headers;
    font-size: 3.8rem;
    padding: 2.5% 0;
    font-weight: 900;
}