section.section {
    padding: 2% 0;
}
.section--title {
    font-weight: 900;    
    padding-bottom: 2%;
    line-height: 1.33;
    font-size: 24px;
    padding: 2% 0;
    font-family: $font-headers;
    font-weight: 900;
    position: relative;
    z-index: 1;
    @include max-breakpoint ($point2) {
        font-size: 16px;
    }
    span {
        color: $alternate-color-dark;
    }
}
.section--video {
    margin: 3% 0;
    padding: 2% 0;
    background-color: $alternate-color;
    @include max-breakpoint ($narrowLayout + 30) {
        padding: 2%;
    }
    @include max-breakpoint ($point3) {
        padding: 3% 0 5%;
    }
}
.section--video-hd {
    font-size: 40px;
    font-family: $font-headers;
    font-weight: 900;
    line-height: 1.31;
    padding-bottom: 2.5%;
    @include max-breakpoint ($point2) {
        font-size: 16px;
    }
    @include max-breakpoint ($point3) {
        padding: 2% 6.75% 4.5%;
    }
    small {
        
        color: $alternate-color-dark;
        display: block;
    }
}

.section.slider {
    position: relative;
   
    @include max-breakpoint ($point2) {
        margin: 4% 0;
        background-color: $main-color;
        position: relative;
        &:before {
            position: absolute;
            content: '';
            width: 130%;
            height: 100%;
            top: 0;
            right: -20%;
            background-color: $alternate-color;
            z-index: 0;
        }
    }
    @include max-breakpoint ($point3) {
        &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: -20%;
            z-index: 3;
            content: '';
            width: 130%;
            position: absolute;
            height: 50vw;
            background: linear-gradient(to bottom, transparent 0%, $alternate-color 60%);
        }
    }
    .owl-carousel {
        @include max-breakpoint ($point3) {
            max-height: 120vw;
            overflow: hidden;
        }
    }
    &.__seeMore {
        &:after {
            display: none;
        }
        .owl-carousel {
            @include max-breakpoint ($point3) {
                max-height: 12200px;
                transition: all 220ms ease;
            }
        }
    }
}
.section .wrapper {
  @include max-breakpoint ($narrowLayout + 30) {
     margin: 0 0;
  }
}
.section-slider--more {
    display: none;
    width: 207px;
    height: 43px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    font-size: 15px;
    line-height: 43px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    z-index: 4;
    font-weight: 600;
    margin-bottom: 4%;
    @include max-breakpoint ($point3) {
        display: block;
        cursor: pointer;
    }
}