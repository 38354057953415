@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900&display=swap&subset=latin-ext');
@import url('https://use.typekit.net/riq1dwq.css');

//fonts
$font-main: 'soleil', sans-serif;
$font-headers: 'Playfair Display', serif;
$font-special: 'Playfair Display', serif;
$font-special2: 'Playfair Display', serif;


//colors
$main-color: #f6f2f1;
$domain-color: #eeded9;
$alternate-color: #f4f4f4;
$alternate-color-dark: #dcb8a4;
$alternate-color-darker: #c4a391;
$alternate-color-light: #f0f0f0;


//breakpoints
$point0: 1180px;
$point1: 1080px;
$point2: 768px;
$point3: 640px;
$point4: 320px;
$point-nav: 1300px;
$articlePoint: 1180px;
$socialsPoint: 1340px;
$socialsPoint2: 1030px;

$narrowLayout: 944px;


//layout vars
$gutter: 20px;
$gutterMobile: 10px;
$layoutWidth: 1180px;
$layoutInternalWidth: 1000px;
$headerHeight: 80px;
$footerHeight: 100px;
$headerMobileHeight: 59px;

