
.main-footer {
    z-index: 2;
    position: relative;
    width: 100%;
    .outer {
        margin: 0px auto;
        width: 100%;
    }

    .wrapper {
        position: relative;
        text-align: center;   
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        overflow: visible;
        font-size: 14px;
        color: #3e3e3e;
        min-height: 30px;
        padding-left: 10px;
        padding-bottom: 15px;
        a {
            margin: 0 10px;
            white-space: nowrap;
        }
    }
}