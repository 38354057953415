[id^="dynacrems-adv-"] {
    // min-height: 94.4%;
    min-height: 380px;
    @include max-breakpoint(1180px) {
        min-height: 370px;
    }
}

.cdc {
    margin: 3% auto;
}
.cdc--wrapper {
    position: relative;
    min-height: 94.4%;
}

.cdc--products {
    line-height: 1.3;

    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.cdc--product {
    border: 4px solid #ccc;
    width: calc(100% / 2 - 14px);
    box-sizing: border-box;
    position: relative;
}
.cdc--product-inner {
    height: 100%;
    padding: 24px 0 50px;
}
.cdc--photo-link {
    display: block;
}
.cdc--photo-wrapper {
    text-align: center;
    position: relative;
    width: 55%;
    /* proporcje zdjęcia: 300x180 */
    overflow: hidden;
    ///float: left;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    @include max-breakpoint($point2 - 180) {
        float: none;
        width: 100%;
    }
}
.cdc--photo {
    max-width: 100%;
    display: block;
    left: 0;
    right: 0;
    margin: 0px auto;
    overflow: hidden;
    @include max-breakpoint($point2 - 180) {
        margin-bottom: 10px;
    }
}

.cdc--details {
    display: flex;
    flex-wrap: wrap;
    padding:0 14px;
    box-sizing: border-box;
    @include max-breakpoint($point2 - 180) {
        padding: 0 5px;
    }
}
.cdc--title {
    text-align: center;
    width: 100%;
    padding: 10px 0;
    font-weight: 600;
    font-size: 20px;
}
article .cdc--title a {
    margin: 3px 0 9px;
    font-weight: 600;
    color: #333;
    margin-bottom: 2%;
    width: 100%;
    font-family: $font-main;
    text-align: center;
    ;
    @include max-breakpoint($point2 - 180) {
        float: none;
        width: 100%;
        text-align: center;
        margin-bottom: 0%;

    }


}
.cdc--subtitle {
    text-align: center;
}
.cdc--description {
    font-size: 1.8rem;
    color: #333;
    font-weight: 400;
    overflow: visible;
    line-height: 1.4;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    @include max-breakpoint ($point0) {
        max-width: 90%;
        padding-right: 10px;
        box-sizing: border-box;
    }
    @include max-breakpoint($point2 - 180) {
        float: none;
        width: 100%;
        max-width: 100%;
        padding-right: 0;
        text-align: center;
    }
    
}

.cdc--price {
    width: 100%;
    font-weight: 700;
    padding: 4% 0;
    font-size: 3rem;
    color: #000;
}

.cdc--old-price {
    text-decoration: line-through;

}

.cdc--current-price {
    color: #ff0000a1;
    text-decoration: none;
    margin-left: 20px;
}

.cdc--external {
    display: block;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    color: #939393;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.03rem;
    padding: 6% 0 6%;
    margin: 0 auto;
    font-weight: 400;
    letter-spacing: 0.09rem;
}

.cdc--external-img {
    width: 41%;
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
}

.cdc--button {
    position: absolute;
    color: #fff;
    line-height: 4rem;
    font-size: 1.4rem;
    padding: 0 40px;
    height: 40px;
    letter-spacing: 0.2rem;
    font-weight: 700;
    box-sizing: border-box;
    z-index: 3;
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;    
    z-index: 3;
    max-width: 160px;
    bottom: -20px;
    &:after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: inline-block;
        color: #fff;
        background: $main-color;
        padding: 0 40px;
        height: 40px;
        border-radius: 25px;
        z-index: -1;
    }
    &:hover:after {
        background: $domain-color;
    }
}

.cdc--button span {
    display: block;
    @include max-breakpoint($point2 - 180) {
        margin: 0 auto;
        text-align: center;
    }
}

.cdc--button span:after {
    
}
.cdc--button:hover span:after {
    background-color: $domain-color;
}

.cdc--product.__sold_out {
    position: relative;
    filter: contrast(.3) brightness(1.6);
}

.cdc--product.__sold_out:after {
    display: block;
    content: '';
    overflow: hidden;
    position: absolute;
    background-color: #333;
    opacity: .7;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    font-size: 200%;
    color: #fff;
    padding-top: 49%;
}

.cdc--product.__sold_out:before {
    display: block;
    content: 'WYPRZEDANE';
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 49%;
    text-align: center;
    font-size: 200%;
    color: #fff;
    z-index: 2;
    transform: rotate(45deg);
}
