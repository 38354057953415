
.section--teaser {
    margin-bottom: 2%;
    box-shadow: 0 2px 15px 8px rgba(0, 0, 0, 0.03);
    article a {
        color: #3e3e3e;
        display: inline-block;
        
        &:hover {
            text-decoration: none;
        }
        
    }
    .article {
        background-color: $main-color;
        padding: 1.6% 6.75%;
        box-shadow: 0 2px 15px 8px rgba(0, 0, 0, 0.4);
        @include max-breakpoint ($narrowLayout + 30) {
            padding: 2.2% 6.75%;
        }
    }
    .article--title {
        border-bottom: 1px solid #979797;
        padding-top: 0;
    }
    .article--lead {
        border-color: #979797;
        margin-bottom: 0;
        @include max-breakpoint($point3) {
            font-size: 14px;
            line-height: 1.43;
        }
    }
    .article--source {
        margin-bottom: 1.6%;
        img {
            width: 30px;
            filter: grayscale(1);
            @include max-breakpoint($point3) {
                display: none;
            }
        }
        @include max-breakpoint($point3) {
            padding: 2% 0;
        }
        @include max-breakpoint($point2) {
            a {
                display: flex;
            }
        }
                

    }
    @include max-breakpoint($point3) {
        margin-bottom: 9%;
    }
}
.section section--teaser a .article--more {
        color: $alternate-color-dark;
}