.author-box {
    background-color: $main-color;
    margin-bottom: 3%;
    .wrapper {
        align-content: center;
        padding: 50px 0;
        @include max-breakpoint ($point3) {
            display: block;
            padding: 40px 0;
        }
    }
}
.author-box--app {
    h1 {
        font-size: 38px;
        line-height: normal;
        font-weight: 900;
        font-family: $font-headers;
        padding: 0 0 1%;
        
        @include max-breakpoint ($socialsPoint) {
            font-size: 30px;
        }
        @include max-breakpoint ($point3) {
            font-size: 26px;
        }
    }
}
.author-box--img-wrapper {
    width: 192px;
    height: 192px;
    border-radius: 50%;
    overflow: hidden;
    float: left;
    img {
        max-width: 100%;
        max-height: 100%;
    }
    @include max-breakpoint ($point3) {
        float: none;
        margin: 0 auto;
    }
}
.author-box--app {
    font-weight: 300;
    font-size: 18px;
    margin-left: 30px;
    margin-left: 230px;
    @include max-breakpoint ($point3) {
        width: 90%;
        margin: 3% auto 0 auto;
        text-align: center;
        font-size: 16px;
    }
    &:nth-child(1) {
        margin-left: 0px;
        @include max-breakpoint ($point3) {
            margin-left: auto;
            margin-top: 0;
        }
    }
}
.author-box--mail {
    font-weight: 500;
    border-top: solid 1px rgba(238, 222, 217, 0.65);
    margin-top: 19px;
    padding-top: 19px;
    padding-left: 0;
    a {
        @include max-breakpoint ($point3) {
            font-size: 14px;
        }
        margin-right: 20px;
        white-space: nowrap;
    }
}

.author-box--mail-ico {
    &:before {
        content: url(images/mail.svg);
        vertical-align: middle;
        margin-right: 10px;
        top: -4px;
        position: relative;
        @include max-breakpoint ($point3) {
            top: -2px;
        }
    }
}
.author-box--office-ico {
    &:before {
        content: url(images/instagram.svg);
        vertical-align: middle;
        margin-right: 10px;
        top: -2px;
        position: relative;
        @include max-breakpoint ($point3) {
            top: 0px;
        }
    }
}
.author--list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include max-breakpoint ($point2 + 170) {
        justify-content: space-around;
    }
    .global--hd {
        margin-top: -2%;
        margin-bottom: 1%;
    }
}
.author--item {
    width: 47.8%;
    position: relative;
    border: 2px solid $domain-color;
    margin-bottom: 3%;
    padding: 22px;
    box-sizing: border-box;
    @include max-breakpoint ($point2 + 170) {
        max-width: 400px;
    }
    @include max-breakpoint ($point2 - 100) {
        width: 100%;
         margin-bottom: 5%;
    }
}
.author--img-wrapper {
    width: 119px;
    height: 119px;
    position: relative;
    border-radius: 50%;
    float: left;
    margin-right: 28px;
    img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 50%;
    }
    @include max-breakpoint ($point1) {
        margin-bottom: 15px;
    }
    @include max-breakpoint ($point2 + 170) {
        float: none;
        margin: 0 auto 15px;
    }
}
.author--data {
    margin-left: 130px;
    @include max-breakpoint ($point2 + 170) {
        margin-left: 0;
    }
}
.author--name {
    font-size: 20px;
    font-family: $font-headers;
    font-weight: 900;
}
.author--function {
    color: #727272;
    font-size: 14px;
}
.author--data .author-box--mail {
    font-size: 14px;
    color: #3e3e3e;
    border: 0;
    padding-top: 12px;
    margin-top: 0;
    a {
        display: block;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 300;
    }
    .author-box--mail-ico:before {
        top: -2px;
    }
    .author-box--office-ico:before {
        top: 0px;
    }
}
.author-more {
    position: absolute;
    right: 0;
    bottom: 0;
    line-height: 32px;
    height: 32px;
    color: #3e3e3e;
    font-size: 14px;
    padding: 0 14px 0 17px;
    background-color: $domain-color;
    &:before {
        content: 'Więcej';
    }
    &:after {
        display: inline-block;
        content: '';
        width: 8px;
        height: 8px;
        vertical-align: middle;
        margin-left: 7px;
        
        border-right: 1px solid #3c3c3c;
        border-bottom: 1px solid #3c3c3c;
        transform: rotate(-45deg);
    }
}