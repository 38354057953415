/// UWAGA TU WYJATKOWO ZE WZGLĘDU NA PRZEJRZYSTOŚĆ STYLE DO MOBILE/HAMBURGER SA NA KOŃCU PLIKU !

@keyframes slideDown {
    from {
        transform: translateY(-100%);
        opacity: .5
    }

    to {
        transform: translateY(0);
        opacity: 1
    }
}

.header {
    width: 100%;
    height: $headerHeight;
    border: 0;
    outline: 0;
    z-index: 8;
    left: 0;
    top: 0;
    right: 0;
    background-color: #fff;
    position: absolute;
    top: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

    @include max-breakpoint($point2) {
        height: $headerHeight - 24;
    }

    > .wrapper {
        height: $headerHeight;
        position: relative;
        overflow: visible;
        
        @include max-breakpoint ($point0 + 30px) {
            padding: 0;
            margin: 0 2%;
        }
        
        @include max-breakpoint($point2) {
            height: $headerHeight - 24;
        }
    }

    &.__js_up {
        position: fixed;
        animation: slideDown .3s ease .1s;
        animation-fill-mode: both;
    }

}

.header {
    h2 {
        font-size: 16px;
        color: #000;
        font-weight: 300;
    }

    li {
        padding: 5px 0;
    }

    a:hover h2 {
        color: #333;
    }

    ul {
        max-height: 0px;
        overflow: hidden;
        transition: all 220ms ease;
        opacity: 0;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        width: 100%;
        max-width: 320px;
        position: relative;
        box-sizing: border-box;
    }

    li {
        border-top: solid 2px #efefef;
        position: relative;
        a {
            padding: 14px 10px 14px 55px;
            display: block;
            box-sizing: border-box;
            @include max-breakpoint($point2) {
                padding: 7px 10px 7px 38px;
            }
            &:before {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $domain-color;
                position: absolute;
                content: '';
                left: 20px;
                top: calc(50% - 5px);
                @include max-breakpoint($point2) {
                    left: 15px;
                    width: 8px;
                    height: 8px;
                    top: calc(50% - 4px);
                }
            }
        }
        &:hover {
            background-color: #f4f4f4;
        }
        
    }

    &.__js_isActive {
        ul {
            max-height: 90vh;
            transition: all 420ms ease;
            overflow-y: auto;
            background-color: rgba(255, 255, 255, 1);
            opacity: 1;
            padding-top: 80px;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
            @include max-breakpoint($point2) {
                padding-top: 56px;
            }
            @include max-breakpoint($point3) {
                width: 100%;
                max-width: none;
            }
        }
    }
}

.header--hd {
    position: relative;
    left: 70px;
    top: 0;
    display: inline-block;
    line-height: $headerHeight;
    font-family: $font-headers;
    font-weight: 800;
    font-size: 38px;
    z-index: 5;
    background: url(images/logo_so.svg) no-repeat 0 17px;
    background-size: contain;
    text-indent: -9999px;
    overflow: hidden;
    width: 150px;
    a {
        display: block;
        height: 100%;
    }
    @include max-breakpoint($point2) {
        line-height: $headerHeight - 24;
        font-size: 20px;
        left: 60px;
        width: 125px;
        background-position: 0 10px;
    }
    @include max-breakpoint($point3) {
        left: 45px;
        width: 90px;
        background-position: 0 14px;
    }
}

.header--nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.header--logo {
    height: 100%;
    position: absolute;
    right: 0px;
    top: 0;

    img {
        max-height: 100%;
    }
}

/*logo klienta*/
.header--customer-logo {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    text-align: right;
    line-height: $headerHeight;
    z-index: 0;
    font-family: Roboto;
    font-size: 14px;

    @include max-breakpoint($point2) {
        line-height: 14px;
        font-size: 12px;
        margin-right: 10px;
    }

    span {
        display: inline-block;

        @include max-breakpoint($point2) {
            width: 80px;
            top: 7px;
            position: relative;
        }
    }

    img {
        max-height: none;
        position: relative;
        transition: all 220ms ease;
        margin-top: 0;
        vertical-align: middle;
        margin-left: 10px;

        @include max-breakpoint($point2) {
            height: $headerHeight - 24;
        }
    }
}

.header--hamburger {
    position: absolute;
    text-indent: -9999px;
    width: 23px;
    height: 18px;
    box-sizing: border-box;
    left: 18px;
    top: 31px;
    cursor: pointer;
    //background-image: url(images/hamburger.png);
    z-index: 2;
    border-top: 2px solid #b3b3b3;
    border-bottom: 2px solid #b3b3b3;
    border-left: 0;
    border-right: 0;
    &:before {
        position: absolute;
        content: '';
        width: 23px;
        height: 2px;
        background-color: #b3b3b3;
        transform: rotate(0deg);
        opacity: 1;
        transition: all 220ms ease;
        top: 6px;
        left: 0px;
    }

    &:after {
        position: absolute;
        content: '';
        width: 21px;
        height: 2px;
        background-color: #b3b3b3;
        transform: rotate(0deg);
        opacity: 0;
        transition: all 220ms ease;
        top: 13px;
        left: 3px;

    }
    @include max-breakpoint($point2) {
        top: 18px;
        left: 16px;
    }
    @include max-breakpoint($point3) {
        left: 7px;
    }
}

.__js_isActive .header--hamburger {
    z-index: 5;
    background-image: none;
    position: relative;
    top: 24px;
    left: 18px;
    width: 31px;
    height: 31px;
    display: block;
    position: absolute;
    border: 0;
    &:before {
        
        content: '';
        width: 21px;
        height: 2px;
        opacity: 0;
        transition: all 220ms ease;
        top: 13px;
        left: 3px;
        position: absolute;
        background-color: #b3b3b3;
        transform: rotate(45deg);
        opacity: 1;
        
    }

    &:after {
        position: absolute;
        content: '';
        width: 21px;
        height: 2px;
        background-color: #b3b3b3;
        transform: rotate(-45deg);
        opacity: 1;
    }
    @include max-breakpoint($point2) {
        top: 15px;
        left: 15px;
    }
    @include max-breakpoint($point3) {
        left: 7px;
    }
    
}
