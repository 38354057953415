article a.interrelated {
    background-color: $alternate-color;
    padding: 20px 50px;
    margin: 2% 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    @include max-breakpoint ($point2) {
        padding: 5% 0 5%;
        &:after {
            position: absolute;
            content: '';
            width: 50%;
            height: 100%;
            top: 0;
            left: -20%;
            background-color: $alternate-color;
            z-index: 0;
        }
        &:before {
            position: absolute;
            content: '';
            width: 50%;
            height: 100%;
            top: 0;
            right: -20%;
            background-color: $alternate-color;
            z-index: 0;
        }
    }
    &:hover {
        text-decoration: none;
    }
    &:after {
        display: block;
        clear: both;
        content: '';
    }
    > div {
        display: inline-block;
        order: 0;
        width: 65%;
        position: relative;
        z-index: 1;
        @include max-breakpoint ($point2) {
            width: 100%;
        }
    }
}
.interrelated .interrelated--foto-wrapper {
    width: 28.47%;
    order: 1;
    @include max-breakpoint ($point2) {
        width: 192px;
        margin: 4% auto 0;
        position: relative;
        &:before {
            display: block;
            position: absolute;
            content: '';
            background-color: $domain-color;
            border-radius: 50%;
            width: 173px;
            height: 175px;
            top: 10px;
            left: -20px;
        } 
    }
    @include max-breakpoint ($point3) {
        margin: 6% auto 3%;
    }
}

.interrelated--hd {
    font-size: 16px;
    color: #333;
    @include max-breakpoint ($point2) {
        margin-top: 1%;
        margin-bottom: 1%;
    }
}
.interrelated--txt {
  font-family: $font-headers;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.42;
  color: #3e3e3e;
  @include max-breakpoint ($point2) {
      width: 100%;
      font-size: 18px;
  }
}
.interrelated--img {
    display: block;
    vertical-align: middle;
    border-radius: 50%;
    order: 1;
    width: 100%;
    position: relative;
    z-index: 1;
}