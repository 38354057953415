html {
  font-size: 10px;
  max-width: 100vw;
    overflow-x: hidden;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
    font-family: $font-main;
    font-size: 2rem;
    font-weight: 400;
    width: 100%;
    color: #3b3b3b;
    box-sizing: border-box;
    background: #fff;
    background-size: 100%;
    position: relative;
    box-sizing: border-box;
    padding-top: $headerHeight;
    
    @include max-breakpoint ($point2) {
        padding-top: $headerHeight - 24;
    }
}

p {
    padding: 0 0 6%;
    @include max-breakpoint ($point2) {
        padding: 0 0 3%;
    }
}
scrollbar {
  width: 8px;
  background: rgba(119, 119, 119, 0.1);
}

scrollbar-track {
  background-color: rgba(223, 223, 223, 0.6);
  border-radius: 0px;
}

scrollbar-thumb {
  border-radius: 1px;
  background: $main-color;
}

::-webkit-scrollbar {
  width: 8px;
  background: rgba(119, 119, 119, 0.1);

}

::-webkit-scrollbar-track {
  background-color: rgba(223, 223, 223, 0.6);
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background: $main-color;
}

.outer {
    position: relative;
    padding: 0 0%;
    margin: 0px auto;
    @include max-breakpoint ($socialsPoint2) {
        overflow: hidden;
    }
    >.wrapper {
        min-height: calc(100vh - 710px);
        overflow-y: hidden;
    }
}

.wrapper {
  max-width: $layoutWidth;
  left: 0;
  right: 0;
  margin: 0px auto;
  box-sizing: border-box;
  z-index: 2;
  position: relative;
  overflow: hidden;
  
  @include max-breakpoint ($socialsPoint) {
      max-width: $narrowLayout;
  }
  @include max-breakpoint ($narrowLayout + 30) {
     margin: 0 2%;
  }
    
  &:after {
    display: block;
    clear: both;
    content: '';
  }
}