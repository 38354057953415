.section-head {
	position: relative;
	margin: 0px auto;
	box-sizing: border-box;
    position: relative;
	z-index: 1;
    padding-top: 581px;
    padding-top: 845px;
    
    @include max-breakpoint (1920px) {
        padding-top: 43.8vw;//33.63vw;
    }
    @include max-breakpoint ($point3) {
        padding-top: 48.03vw;
    }
    > .wrapper {
		margin: 0px auto;
		position: absolute;
		width: 100%;
        height: 100%;
		left: 0;
		right: 0;
        top: 0;
        color: #fff;
        @include max-breakpoint ($point2 + 100) {
            clear: both;
            position: relative;
            padding-top:9.60%;
            width: 100%;
        }
	}
	.section-head--full-image {
		max-width: 100%;
        max-width: 1920px;
		display: block;
		margin: 0px auto;
		// max-height: 80vh;
		width: 100%;
		object-fit: cover;
		object-position: top;
		margin-bottom: -40px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
		@include max-breakpoint (890px) {
            margin-bottom: -50px;
        }
         @include max-breakpoint ($point2) {
        
            max-width: 111%;
            max-width: 1600px;
            display: block;
            margin: 0 auto;
            width: 111%;
            object-fit: cover;
            object-position: top;
            margin-bottom: -40px;
            position: absolute;
            top: 0;
            left: 50%;
            right: 0;
            margin: 0 auto;
            transform: translateX(-50%);
        
        }
        
        
        
	}

}
.section-head--image-author {
    font-size: 12px;
    opacity: .5;
    position: absolute;
    bottom: 7px;
    max-width: $layoutWidth;
    text-align: right;
    margin: 0 auto;
    left: 0;
    right: 0;
    display: block;
    @include max-breakpoint ($socialsPoint) {
      max-width: $narrowLayout;
    }
    @include max-breakpoint ($narrowLayout + 30) {
        margin: 0 2%;
    }
}