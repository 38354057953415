article.article {
    font-size: 21px;
    line-height: 1.61905; //1.43;
    color: #3e3e3e;
    background-color: #fff;
    position: relative;
    margin: 0px auto;
    padding: 4% 6.78%;
    font-weight: 100;
    @include max-breakpoint ($articlePoint) {
        padding: 40px 5% 5%;
    }
    @include max-breakpoint ($narrowLayout + 30) {
       padding: 40px 6% 6%;
    }
    @include max-breakpoint ($point3) {
        font-size: 16px;
        line-height: 1.41;
    }
    &.__js_onScreen {
        // background-color: #fff;
    }

    a {
        color: #d09677;
        &:hover {
            text-decoration: underline;
        }
    }

    p {
        padding: 1% 0;
        
        @include max-breakpoint($point2) {
            padding: 1% 0;
        }

        @include max-breakpoint($point3) {
            font-size: 16px;
        }

        &:empty {
            display: none !important;
            padding: 0 !important;
        }
    }

    ul {
        padding: 0 0 3%;
    }

    li {
        padding-left: 10px;
        margin-bottom: 4px;
        position: relative;

        &:before {
            display: block;
            position: absolute;
            top: 10px;
            left: 0;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background-color: #0b2024;
            content: '';
        }
    }    
}


.article--wrapper {
    
}

.article--ajax-wrapper {
    margin: 0px auto;
}

.article--ajax-wrapper:nth-of-type(2),
.article--ajax-wrapper:nth-of-type(3) {
    // border-top: 2px solid #f1f2f3;
    padding-top: $gutter *1;
}
.article--category {
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    background-color: $main-color;
    line-height: 44px;
    padding: 0 30px;
    @include max-breakpoint ($point2) {
        font-size: 10px;
        line-height: 38px;
        padding: 0 25px;
    }
    @include max-breakpoint ($point3) {
        font-size: 10px;
        line-height: 34px;
        padding: 0 20px;
    }
}
.article--date {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 14px;
    @include max-breakpoint($point2) {
        top: 10px;
        right: 15px;
        font-size: 10px;
    }
}
.article--title {
    font-size: 48px;
    line-height: normal;
    font-weight: 900;
    font-family: $font-headers;
    padding: 3% 0 3%;
    border-bottom: solid 2px #e7e7e7;
    margin-bottom: 2%;
    
    @include max-breakpoint($socialsPoint2 + 110) {
        font-size: 40px;
    }
    @include max-breakpoint ($point3) {
        font-size: 18px;
    }
     
}
article .article--author{
    width: 100%;
    @include max-breakpoint($point2) {
        display: flex;
    }
    a {
        color: #3e3e3e;
        &:hover {
            text-decoration: none;
        }
    }
}

@keyframes a {
  from {width:8px;height:8px;background-color: #fffefe }
  to {width: 18px;height: 18px;background-color: $alternate-color-dark}
}

.article--loader {
    margin: 100px auto;
    width: 18px;
    height: 18px;
    position: relative;
    div {
        width: 18px;
        height: 18px;
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: $alternate-color-dark;
        border-radius:50%;
        
        animation: a 800ms alternate infinite 800ms;
    }
    &:before {
        position: absolute;
        display:block;
        width: 18px;
        height: 18px;
        content: '';
        border-radius:50%;
        background-color: $alternate-color-dark;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-left: -20px;
        animation: a 800ms alternate infinite 400ms;
    }
    &:after {
        position: absolute;
        display:block;
        width: 18px;
        height: 18px;
        content: '';
        border-radius:50%;
        background-color: $alternate-color-dark;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-left: 40px;
        animation: a 800ms alternate infinite;
    }
}
.article--lead {
    font-size: 22px;
    line-height: 1.45;
    padding-bottom: 3%;
    @include max-breakpoint ($point1) {
        font-size: 21px;
    }
    @include max-breakpoint ($point2) {
        font-size: 20px;
        font-weight: 500; 
        padding-bottom: 4%;
    }
    @include max-breakpoint ($point3) {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 5%;
    }
}

.article--source {
    vertical-align: middle;
    display: flex;
    margin-bottom: 3%;
    font-size: 16px;
    div, a {
        display: inline-block;
        vertical-align: middle;
        margin-right: 30px;
        font-size: 16px;
        @include max-breakpoint($point2) {
            font-size: 11px;
            line-height: 1.36;
            max-width: 46%;
            box-sizing: border-box;
            margin-right: 0;
            padding-right: 4%;
            //display: flex;
            align-content: center;
            display: flex;
            align-items: center;
            &:nth-of-type(n){
                width: 100%;
            }
            &:nth-of-type(2n - 1){
                width: auto;
            }
        }
    }
    img {
        border-radius: 50%;
        margin-right: 5px;
        vertical-align: middle;
        @include max-breakpoint($point2) {
            max-width: 30px;
            max-height: 30px;
            margin-right: 6px;
        }
    }
    @include max-breakpoint($point3) {
        margin-bottom: 4%;
    }
}
.article--source-author {
    width: 100%;
    @include max-breakpoint($point2) {
        display: flex;
    }
}
.article-text--wrapper {
    padding: 0 50px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    @include max-breakpoint ($point2) {
        padding: 0 0px;
    }
    h2 {
        line-height: 1.33;
        font-size: 24px;
        padding: 2% 0;
        font-family: $font-headers;
        font-weight: 900;
        @include max-breakpoint($point2) {
            font-size: 20px;
        }
        @include max-breakpoint($point2) {
          font-size: 18px;
          font-weight: 900;
        }
        @include max-breakpoint($point3) {
            padding: 5% 0 4%;
        }
    }
}
article.article {
    h2 {
        line-height: 1.33;
        font-size: 24px;
        padding: 2% 0;
        font-family: $font-headers;
        font-weight: 900;
        @include max-breakpoint($point2) {
            font-size: 20px;
        }
        @include max-breakpoint($point2) {
          font-size: 18px;
          font-weight: 900;
        }
        @include max-breakpoint($point3) {
            padding: 5% 0 4%;
        }
    }
}
.article--quotation, .quote {
    font-family: $font-headers;
    font-size: 2.4rem;
    line-height: 1.33;
    padding: 40px 0px;
    font-weight: 800;
    max-width: 580px;
    margin: 0 auto;
    text-align: center;
    span {
        display: block;
        font-family: $font-main;
        font-size: 1.6rem;
        line-height: 1;
        border-top: solid 1px #eeded9;
        padding-top: 3%;
        margin-top: 3%;
        font-weight: 500;
    }
    @include max-breakpoint($point2) {
        font-size: 18px;
        line-height: 1.61;
        padding: 7% 0px;
    }
    .quote_title {display: none;}
}
.quote_signature_img {
    max-width: 212px;
    margin: 0 auto;
    img {
        max-width: 100%;
        border-radius: 50%;
    }
}
.article--photo {
    margin: 2% auto;

    @include max-breakpoint($point3) {
        width: 112%;
        margin-left: -6%;
    }
}

.article--photo-wrapper {
    position: relative;

    > img {
        margin: 0px auto;
        display: block;
        max-width: 100%;
        width: 100%;
    }
}

.article--photo-source {
    
    bottom: 0;
    left: 0;
    padding: 1% 0;
    font-size: 14px;
    line-height: 2.29;
    
    @include max-breakpoint ($point3) {
        position: relative;
        width: 100%;
    }
}


.article--photo-lead {
    background-color: #eaeaea;
    font-size: 1.3rem;
    padding: $gutter;
}

$animationLength: 1s;
$animationRadius: 12px;
$dotSize: 8px;

@keyframes animateDot1 {
    0% {
        transform: rotate(0deg) translateX(-$animationRadius);
    }

    25% {
        transform: rotate(180deg) translateX(-$animationRadius);
    }

    75% {
        transform: rotate(180deg) translateX(-$animationRadius);
    }

    100% {
        transform: rotate(360deg) translateX(-$animationRadius);
    }
}

@keyframes animateDot2 {
    0% {
        transform: rotate(0deg) translateX($animationRadius);
    }

    25% {
        transform: rotate(180deg) translateX($animationRadius);
    }

    75% {
        transform: rotate(180deg) translateX($animationRadius);
    }

    100% {
        transform: rotate(360deg) translateX($animationRadius);
    }
}

.article_loader {
    display: inline-block;
    width: $dotSize;
    height: $dotSize;
    border-radius: $dotSize * 0.5;
    background: #ccc;
    position: absolute;
    animation: animateDot1 $animationLength linear infinite;
    left: $animationRadius;
    background: $main-color;
    left: 50%;

    &:after {
        content: '';
        display: inline-block;
        width: $dotSize;
        height: $dotSize;
        border-radius: $dotSize * 0.5;
        background: #ccc;
        animation: animateDot2 $animationLength linear infinite;
        left: $animationRadius;
        position: absolute;
        margin: 0px auto;
    }
}

.banner--wrapper {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 24px;

    > img {
        width: 100%;
        max-width: 970px;
        object-fit: contain;
    }
}
.article--more {
    position: relative;
    color: $alternate-color-dark;
    &:before {
        content: '... ';
        color: #3e3e3e;
        margin-left: -3px;
    }
}
.article--more:after {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-left: 7px;
    vertical-align: middle;
    border-right: 1px solid $alternate-color-dark;
    border-bottom: 1px solid $alternate-color-dark;
    transform: rotate(-45deg);
}
a:hover .article--more {
    color: $alternate-color-dark;
    transition: color 220ms ease;
    &:after {
        border-color: $alternate-color-dark;
        transition: all 220ms ease;
    }
    &:before {
        content: '... ';
        color: #3e3e3e;
        margin-left: -3px;
    }
}
a:hover {
}