@import "_reset.scss";

@import "_vars.scss";

//mixins
// do rwd
@mixin max-breakpoint($point) {
  @media screen and (max-width: $point) {
    @content;
  }
}

@import "_main.scss";
@import "_global.scss";
@import "__header.scss";
@import "__article.scss";
@import "__section_head.scss";

@import "__owlslider.scss";

@import "__interrelated.scss";
@import "__teaser.scss";
@import "__section.scss";
@import "__section_teaser.scss";
@import "__author.scss";
@import "__socials.scss";
@import "__cdc.scss";
//@import "__products.scss";

//@import "__article_author.scss";
//@import "__article_socials.scss";
//@import "__article_activity.scss";
//@import "__article_blockquote.scss";
//@import "__patchwork.scss";
//@import "__patchwork_hover.scss";
//@import "__accordion.scss";
//@import "__tabs.scss";
//
//@import "__section_before-after.scss";

@import "__footer.scss";

