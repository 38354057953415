@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900&display=swap&subset=latin-ext");
@import url("https://use.typekit.net/riq1dwq.css");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, big, em, img, q,
abbr, address, cite, code, del, dfn, em, img, ins, samp,
small, strong, tt, var, b, u, i, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, figure,
table, caption, tbody, tfoot, thead, tr, th, td,
time, mark, audio, video, tbody {
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, tr, tbody {
  display: block; }

ul {
  list-style: none; }

a {
  text-decoration: none;
  color: #333; }

html {
  font-size: 10px;
  max-width: 100vw;
  overflow-x: hidden; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

body {
  font-family: "soleil", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  width: 100%;
  color: #3b3b3b;
  box-sizing: border-box;
  background: #fff;
  background-size: 100%;
  position: relative;
  box-sizing: border-box;
  padding-top: 80px; }
  @media screen and (max-width: 768px) {
    body {
      padding-top: 56px; } }

p {
  padding: 0 0 6%; }
  @media screen and (max-width: 768px) {
    p {
      padding: 0 0 3%; } }

scrollbar {
  width: 8px;
  background: rgba(119, 119, 119, 0.1); }

scrollbar-track {
  background-color: rgba(223, 223, 223, 0.6);
  border-radius: 0px; }

scrollbar-thumb {
  border-radius: 1px;
  background: #f6f2f1; }

::-webkit-scrollbar {
  width: 8px;
  background: rgba(119, 119, 119, 0.1); }

::-webkit-scrollbar-track {
  background-color: rgba(223, 223, 223, 0.6);
  border-radius: 0px; }

::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background: #f6f2f1; }

.outer {
  position: relative;
  padding: 0 0%;
  margin: 0px auto; }
  @media screen and (max-width: 1030px) {
    .outer {
      overflow: hidden; } }
  .outer > .wrapper {
    min-height: calc(100vh - 710px);
    overflow-y: hidden; }

.wrapper {
  max-width: 1180px;
  left: 0;
  right: 0;
  margin: 0px auto;
  box-sizing: border-box;
  z-index: 2;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 1340px) {
    .wrapper {
      max-width: 944px; } }
  @media screen and (max-width: 974px) {
    .wrapper {
      margin: 0 2%; } }
  .wrapper:after {
    display: block;
    clear: both;
    content: ''; }

._2do {
  border: 2px dashed grey;
  padding: 10%;
  text-align: center;
  font-size: 5vw;
  margin-bottom: 3%; }

.global--flower {
  position: fixed;
  max-width: 17.3%;
  z-index: 0; }

.global--header {
  padding-bottom: 10px;
  color: #eeded9;
  text-align: center;
  position: relative;
  z-index: 2; }
  .global--header h3 {
    display: inline-block;
    font-size: 2.4rem;
    font-weight: 400;
    color: #666;
    padding: 1% 0;
    margin-bottom: 1%;
    font-weight: 500;
    letter-spacing: 0.3rem; }

.global--hd {
  padding-bottom: 10px;
  text-align: center;
  position: relative;
  z-index: 2;
  width: 100%; }
  .global--hd h3 {
    font-size: 3.2rem;
    padding: 3% 0;
    font-family: "Playfair Display", serif;
    font-weight: 800; }
    @media screen and (max-width: 768px) {
      .global--hd h3 {
        font-size: 2.4rem; } }

.global--center {
  text-align: center; }

.__js_preload {
  opacity: .5; }

.__js_inViewport {
  opacity: 0;
  top: -25px;
  position: relative;
  transition: all 390ms ease; }

.__js_onScreen {
  opacity: 1;
  top: 0px;
  position: relative;
  transition: all 390ms ease; }

.global--offer {
  position: fixed;
  right: 0;
  top: 15vh;
  z-index: 10;
  text-indent: -9999px;
  width: 50px;
  height: 173px;
  background: url(images/static/offer.png); }
  @media screen and (max-width: 640px) {
    .global--offer {
      background-size: contain;
      width: 40px;
      height: 145px; } }

.global--fixed-button {
  position: fixed;
  top: calc(50% - 100px);
  right: 0;
  color: #fff;
  z-index: 3;
  font-family: "Playfair Display", serif;
  padding-left: 50px;
  height: 262px;
  max-width: 0;
  transition: all 320ms ease 100ms;
  overflow: hidden;
  background: transparent url(images/static/bg-metka.jpg) 50px 0 no-repeat; }
  .global--fixed-button > div {
    transform: rotate(-90deg) translateY(-100%) translateX(-50%);
    position: absolute;
    left: 0;
    width: 262px;
    background-color: #eeded9;
    border-radius: 13px 13px 0 0;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin-left: -56px;
    margin-top: -25px;
    cursor: pointer;
    font-size: 1.9rem;
    font-weight: 700px;
    letter-spacing: 0.2rem;
    transition: all 220ms ease 200ms; }
  .global--fixed-button:hover {
    max-width: 300px;
    transition: all 220ms ease; }
    .global--fixed-button:hover > div {
      background-color: #f4f4f4;
      transition: all 220ms ease; }
  .global--fixed-button a {
    display: block;
    width: 114px;
    height: 34px;
    background: url(images/pobierz-a.png);
    text-indent: -9999px;
    overflow: hidden;
    margin: 169px 30px 0; }
    .global--fixed-button a:nth-of-type(2) {
      background: url(images/pobierz-g.png);
      margin: 10px 30px; }
  @media screen and (max-width: 768px) {
    .global--fixed-button {
      height: 200px; }
      .global--fixed-button > div {
        font-size: 1.7rem;
        width: 200px;
        transform: rotate(-90deg) translateY(-38%) translateX(-50%);
        margin-top: -25px; }
      .global--fixed-button a {
        margin: 109px 20px 0; }
        .global--fixed-button a:nth-of-type(2) {
          margin: 10px 20px; } }

.global--edition-hd {
  font-size: 30px;
  line-height: 2.04;
  text-align: center;
  padding: 2% 0;
  position: relative;
  margin: auto; }
  .global--edition-hd:after {
    display: block;
    position: absolute;
    content: '';
    height: 1px;
    background-color: #eeded9;
    top: 50%;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 0; }
  .global--edition-hd span {
    display: inline-block;
    padding: 0 22px;
    background-color: #fff;
    position: relative;
    z-index: 1; }

figure img {
  width: 100%;
  max-width: 100% !important;
  display: block;
  margin: 0 auto; }

article.article figure {
  display: block;
  width: 100%;
  padding: 3% 0; }
  article.article figure p {
    padding: 0; }

article.article h2 + figure {
  margin-top: -4%; }

figure figcaption {
  font-size: 14px;
  line-height: normal; }

.video.youtube iframe {
  width: 100%;
  margin: 0 auto; }

.video {
  padding: 3% 0; }

.global---tag-hd {
  text-align: center;
  margin: 0 0 3%;
  background-color: #f6f2f1;
  font-family: "Playfair Display", serif;
  font-size: 3.8rem;
  padding: 2.5% 0;
  font-weight: 900; }

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: .5; }
  to {
    transform: translateY(0);
    opacity: 1; } }

.header {
  width: 100%;
  height: 80px;
  border: 0;
  outline: 0;
  z-index: 8;
  left: 0;
  top: 0;
  right: 0;
  background-color: #fff;
  position: absolute;
  top: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 768px) {
    .header {
      height: 56px; } }
  .header > .wrapper {
    height: 80px;
    position: relative;
    overflow: visible; }
    @media screen and (max-width: 1210px) {
      .header > .wrapper {
        padding: 0;
        margin: 0 2%; } }
    @media screen and (max-width: 768px) {
      .header > .wrapper {
        height: 56px; } }
  .header.__js_up {
    position: fixed;
    animation: slideDown .3s ease .1s;
    animation-fill-mode: both; }

.header h2 {
  font-size: 16px;
  color: #000;
  font-weight: 300; }

.header li {
  padding: 5px 0; }

.header a:hover h2 {
  color: #333; }

.header ul {
  max-height: 0px;
  overflow: hidden;
  transition: all 220ms ease;
  opacity: 0;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  width: 100%;
  max-width: 320px;
  position: relative;
  box-sizing: border-box; }

.header li {
  border-top: solid 2px #efefef;
  position: relative; }
  .header li a {
    padding: 14px 10px 14px 55px;
    display: block;
    box-sizing: border-box; }
    @media screen and (max-width: 768px) {
      .header li a {
        padding: 7px 10px 7px 38px; } }
    .header li a:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #eeded9;
      position: absolute;
      content: '';
      left: 20px;
      top: calc(50% - 5px); }
      @media screen and (max-width: 768px) {
        .header li a:before {
          left: 15px;
          width: 8px;
          height: 8px;
          top: calc(50% - 4px); } }
  .header li:hover {
    background-color: #f4f4f4; }

.header.__js_isActive ul {
  max-height: 90vh;
  transition: all 420ms ease;
  overflow-y: auto;
  background-color: white;
  opacity: 1;
  padding-top: 80px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16); }
  @media screen and (max-width: 768px) {
    .header.__js_isActive ul {
      padding-top: 56px; } }
  @media screen and (max-width: 640px) {
    .header.__js_isActive ul {
      width: 100%;
      max-width: none; } }

.header--hd {
  position: relative;
  left: 70px;
  top: 0;
  display: inline-block;
  line-height: 80px;
  font-family: "Playfair Display", serif;
  font-weight: 800;
  font-size: 38px;
  z-index: 5;
  background: url(images/logo_so.svg) no-repeat 0 17px;
  background-size: contain;
  text-indent: -9999px;
  overflow: hidden;
  width: 150px; }
  .header--hd a {
    display: block;
    height: 100%; }
  @media screen and (max-width: 768px) {
    .header--hd {
      line-height: 56px;
      font-size: 20px;
      left: 60px;
      width: 125px;
      background-position: 0 10px; } }
  @media screen and (max-width: 640px) {
    .header--hd {
      left: 45px;
      width: 90px;
      background-position: 0 14px; } }

.header--nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

.header--logo {
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0; }
  .header--logo img {
    max-height: 100%; }

/*logo klienta*/
.header--customer-logo {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  text-align: right;
  line-height: 80px;
  z-index: 0;
  font-family: Roboto;
  font-size: 14px; }
  @media screen and (max-width: 768px) {
    .header--customer-logo {
      line-height: 14px;
      font-size: 12px;
      margin-right: 10px; } }
  .header--customer-logo span {
    display: inline-block; }
    @media screen and (max-width: 768px) {
      .header--customer-logo span {
        width: 80px;
        top: 7px;
        position: relative; } }
  .header--customer-logo img {
    max-height: none;
    position: relative;
    transition: all 220ms ease;
    margin-top: 0;
    vertical-align: middle;
    margin-left: 10px; }
    @media screen and (max-width: 768px) {
      .header--customer-logo img {
        height: 56px; } }

.header--hamburger {
  position: absolute;
  text-indent: -9999px;
  width: 23px;
  height: 18px;
  box-sizing: border-box;
  left: 18px;
  top: 31px;
  cursor: pointer;
  z-index: 2;
  border-top: 2px solid #b3b3b3;
  border-bottom: 2px solid #b3b3b3;
  border-left: 0;
  border-right: 0; }
  .header--hamburger:before {
    position: absolute;
    content: '';
    width: 23px;
    height: 2px;
    background-color: #b3b3b3;
    transform: rotate(0deg);
    opacity: 1;
    transition: all 220ms ease;
    top: 6px;
    left: 0px; }
  .header--hamburger:after {
    position: absolute;
    content: '';
    width: 21px;
    height: 2px;
    background-color: #b3b3b3;
    transform: rotate(0deg);
    opacity: 0;
    transition: all 220ms ease;
    top: 13px;
    left: 3px; }
  @media screen and (max-width: 768px) {
    .header--hamburger {
      top: 18px;
      left: 16px; } }
  @media screen and (max-width: 640px) {
    .header--hamburger {
      left: 7px; } }

.__js_isActive .header--hamburger {
  z-index: 5;
  background-image: none;
  position: relative;
  top: 24px;
  left: 18px;
  width: 31px;
  height: 31px;
  display: block;
  position: absolute;
  border: 0; }
  .__js_isActive .header--hamburger:before {
    content: '';
    width: 21px;
    height: 2px;
    opacity: 0;
    transition: all 220ms ease;
    top: 13px;
    left: 3px;
    position: absolute;
    background-color: #b3b3b3;
    transform: rotate(45deg);
    opacity: 1; }
  .__js_isActive .header--hamburger:after {
    position: absolute;
    content: '';
    width: 21px;
    height: 2px;
    background-color: #b3b3b3;
    transform: rotate(-45deg);
    opacity: 1; }
  @media screen and (max-width: 768px) {
    .__js_isActive .header--hamburger {
      top: 15px;
      left: 15px; } }
  @media screen and (max-width: 640px) {
    .__js_isActive .header--hamburger {
      left: 7px; } }

article.article {
  font-size: 21px;
  line-height: 1.61905;
  color: #3e3e3e;
  background-color: #fff;
  position: relative;
  margin: 0px auto;
  padding: 4% 6.78%;
  font-weight: 100; }
  @media screen and (max-width: 1180px) {
    article.article {
      padding: 40px 5% 5%; } }
  @media screen and (max-width: 974px) {
    article.article {
      padding: 40px 6% 6%; } }
  @media screen and (max-width: 640px) {
    article.article {
      font-size: 16px;
      line-height: 1.41; } }
  article.article a {
    color: #d09677; }
    article.article a:hover {
      text-decoration: underline; }
  article.article p {
    padding: 1% 0; }
    @media screen and (max-width: 768px) {
      article.article p {
        padding: 1% 0; } }
    @media screen and (max-width: 640px) {
      article.article p {
        font-size: 16px; } }
    article.article p:empty {
      display: none !important;
      padding: 0 !important; }
  article.article ul {
    padding: 0 0 3%; }
  article.article li {
    padding-left: 10px;
    margin-bottom: 4px;
    position: relative; }
    article.article li:before {
      display: block;
      position: absolute;
      top: 10px;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: #0b2024;
      content: ''; }

.article--ajax-wrapper {
  margin: 0px auto; }

.article--ajax-wrapper:nth-of-type(2),
.article--ajax-wrapper:nth-of-type(3) {
  padding-top: 20px; }

.article--category {
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  background-color: #f6f2f1;
  line-height: 44px;
  padding: 0 30px; }
  @media screen and (max-width: 768px) {
    .article--category {
      font-size: 10px;
      line-height: 38px;
      padding: 0 25px; } }
  @media screen and (max-width: 640px) {
    .article--category {
      font-size: 10px;
      line-height: 34px;
      padding: 0 20px; } }

.article--date {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 14px; }
  @media screen and (max-width: 768px) {
    .article--date {
      top: 10px;
      right: 15px;
      font-size: 10px; } }

.article--title {
  font-size: 48px;
  line-height: normal;
  font-weight: 900;
  font-family: "Playfair Display", serif;
  padding: 3% 0 3%;
  border-bottom: solid 2px #e7e7e7;
  margin-bottom: 2%; }
  @media screen and (max-width: 1140px) {
    .article--title {
      font-size: 40px; } }
  @media screen and (max-width: 640px) {
    .article--title {
      font-size: 18px; } }

article .article--author {
  width: 100%; }
  @media screen and (max-width: 768px) {
    article .article--author {
      display: flex; } }
  article .article--author a {
    color: #3e3e3e; }
    article .article--author a:hover {
      text-decoration: none; }

@keyframes a {
  from {
    width: 8px;
    height: 8px;
    background-color: #fffefe; }
  to {
    width: 18px;
    height: 18px;
    background-color: #dcb8a4; } }

.article--loader {
  margin: 100px auto;
  width: 18px;
  height: 18px;
  position: relative; }
  .article--loader div {
    width: 18px;
    height: 18px;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #dcb8a4;
    border-radius: 50%;
    animation: a 800ms alternate infinite 800ms; }
  .article--loader:before {
    position: absolute;
    display: block;
    width: 18px;
    height: 18px;
    content: '';
    border-radius: 50%;
    background-color: #dcb8a4;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-left: -20px;
    animation: a 800ms alternate infinite 400ms; }
  .article--loader:after {
    position: absolute;
    display: block;
    width: 18px;
    height: 18px;
    content: '';
    border-radius: 50%;
    background-color: #dcb8a4;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-left: 40px;
    animation: a 800ms alternate infinite; }

.article--lead {
  font-size: 22px;
  line-height: 1.45;
  padding-bottom: 3%; }
  @media screen and (max-width: 1080px) {
    .article--lead {
      font-size: 21px; } }
  @media screen and (max-width: 768px) {
    .article--lead {
      font-size: 20px;
      font-weight: 500;
      padding-bottom: 4%; } }
  @media screen and (max-width: 640px) {
    .article--lead {
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 5%; } }

.article--source {
  vertical-align: middle;
  display: flex;
  margin-bottom: 3%;
  font-size: 16px; }
  .article--source div, .article--source a {
    display: inline-block;
    vertical-align: middle;
    margin-right: 30px;
    font-size: 16px; }
    @media screen and (max-width: 768px) {
      .article--source div, .article--source a {
        font-size: 11px;
        line-height: 1.36;
        max-width: 46%;
        box-sizing: border-box;
        margin-right: 0;
        padding-right: 4%;
        align-content: center;
        display: flex;
        align-items: center; }
        .article--source div:nth-of-type(n), .article--source a:nth-of-type(n) {
          width: 100%; }
        .article--source div:nth-of-type(2n - 1), .article--source a:nth-of-type(2n - 1) {
          width: auto; } }
  .article--source img {
    border-radius: 50%;
    margin-right: 5px;
    vertical-align: middle; }
    @media screen and (max-width: 768px) {
      .article--source img {
        max-width: 30px;
        max-height: 30px;
        margin-right: 6px; } }
  @media screen and (max-width: 640px) {
    .article--source {
      margin-bottom: 4%; } }

.article--source-author {
  width: 100%; }
  @media screen and (max-width: 768px) {
    .article--source-author {
      display: flex; } }

.article-text--wrapper {
  padding: 0 50px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .article-text--wrapper {
      padding: 0 0px; } }
  .article-text--wrapper h2 {
    line-height: 1.33;
    font-size: 24px;
    padding: 2% 0;
    font-family: "Playfair Display", serif;
    font-weight: 900; }
    @media screen and (max-width: 768px) {
      .article-text--wrapper h2 {
        font-size: 20px; } }
    @media screen and (max-width: 768px) {
      .article-text--wrapper h2 {
        font-size: 18px;
        font-weight: 900; } }
    @media screen and (max-width: 640px) {
      .article-text--wrapper h2 {
        padding: 5% 0 4%; } }

article.article h2 {
  line-height: 1.33;
  font-size: 24px;
  padding: 2% 0;
  font-family: "Playfair Display", serif;
  font-weight: 900; }
  @media screen and (max-width: 768px) {
    article.article h2 {
      font-size: 20px; } }
  @media screen and (max-width: 768px) {
    article.article h2 {
      font-size: 18px;
      font-weight: 900; } }
  @media screen and (max-width: 640px) {
    article.article h2 {
      padding: 5% 0 4%; } }

.article--quotation, .quote {
  font-family: "Playfair Display", serif;
  font-size: 2.4rem;
  line-height: 1.33;
  padding: 40px 0px;
  font-weight: 800;
  max-width: 580px;
  margin: 0 auto;
  text-align: center; }
  .article--quotation span, .quote span {
    display: block;
    font-family: "soleil", sans-serif;
    font-size: 1.6rem;
    line-height: 1;
    border-top: solid 1px #eeded9;
    padding-top: 3%;
    margin-top: 3%;
    font-weight: 500; }
  @media screen and (max-width: 768px) {
    .article--quotation, .quote {
      font-size: 18px;
      line-height: 1.61;
      padding: 7% 0px; } }
  .article--quotation .quote_title, .quote .quote_title {
    display: none; }

.quote_signature_img {
  max-width: 212px;
  margin: 0 auto; }
  .quote_signature_img img {
    max-width: 100%;
    border-radius: 50%; }

.article--photo {
  margin: 2% auto; }
  @media screen and (max-width: 640px) {
    .article--photo {
      width: 112%;
      margin-left: -6%; } }

.article--photo-wrapper {
  position: relative; }
  .article--photo-wrapper > img {
    margin: 0px auto;
    display: block;
    max-width: 100%;
    width: 100%; }

.article--photo-source {
  bottom: 0;
  left: 0;
  padding: 1% 0;
  font-size: 14px;
  line-height: 2.29; }
  @media screen and (max-width: 640px) {
    .article--photo-source {
      position: relative;
      width: 100%; } }

.article--photo-lead {
  background-color: #eaeaea;
  font-size: 1.3rem;
  padding: 20px; }

@keyframes animateDot1 {
  0% {
    transform: rotate(0deg) translateX(-12px); }
  25% {
    transform: rotate(180deg) translateX(-12px); }
  75% {
    transform: rotate(180deg) translateX(-12px); }
  100% {
    transform: rotate(360deg) translateX(-12px); } }

@keyframes animateDot2 {
  0% {
    transform: rotate(0deg) translateX(12px); }
  25% {
    transform: rotate(180deg) translateX(12px); }
  75% {
    transform: rotate(180deg) translateX(12px); }
  100% {
    transform: rotate(360deg) translateX(12px); } }

.article_loader {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #ccc;
  position: absolute;
  animation: animateDot1 1s linear infinite;
  left: 12px;
  background: #f6f2f1;
  left: 50%; }
  .article_loader:after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #ccc;
    animation: animateDot2 1s linear infinite;
    left: 12px;
    position: absolute;
    margin: 0px auto; }

.banner--wrapper {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 24px; }
  .banner--wrapper > img {
    width: 100%;
    max-width: 970px;
    object-fit: contain; }

.article--more {
  position: relative;
  color: #dcb8a4; }
  .article--more:before {
    content: '... ';
    color: #3e3e3e;
    margin-left: -3px; }

.article--more:after {
  content: '';
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-left: 7px;
  vertical-align: middle;
  border-right: 1px solid #dcb8a4;
  border-bottom: 1px solid #dcb8a4;
  transform: rotate(-45deg); }

a:hover .article--more {
  color: #dcb8a4;
  transition: color 220ms ease; }
  a:hover .article--more:after {
    border-color: #dcb8a4;
    transition: all 220ms ease; }
  a:hover .article--more:before {
    content: '... ';
    color: #3e3e3e;
    margin-left: -3px; }

.section-head {
  position: relative;
  margin: 0px auto;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  padding-top: 581px;
  padding-top: 845px; }
  @media screen and (max-width: 1920px) {
    .section-head {
      padding-top: 43.8vw; } }
  @media screen and (max-width: 640px) {
    .section-head {
      padding-top: 48.03vw; } }
  .section-head > .wrapper {
    margin: 0px auto;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    color: #fff; }
    @media screen and (max-width: 868px) {
      .section-head > .wrapper {
        clear: both;
        position: relative;
        padding-top: 9.60%;
        width: 100%; } }
  .section-head .section-head--full-image {
    max-width: 100%;
    max-width: 1920px;
    display: block;
    margin: 0px auto;
    width: 100%;
    object-fit: cover;
    object-position: top;
    margin-bottom: -40px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0; }
    @media screen and (max-width: 890px) {
      .section-head .section-head--full-image {
        margin-bottom: -50px; } }
    @media screen and (max-width: 768px) {
      .section-head .section-head--full-image {
        max-width: 111%;
        max-width: 1600px;
        display: block;
        margin: 0 auto;
        width: 111%;
        object-fit: cover;
        object-position: top;
        margin-bottom: -40px;
        position: absolute;
        top: 0;
        left: 50%;
        right: 0;
        margin: 0 auto;
        transform: translateX(-50%); } }

.section-head--image-author {
  font-size: 12px;
  opacity: .5;
  position: absolute;
  bottom: 7px;
  max-width: 1180px;
  text-align: right;
  margin: 0 auto;
  left: 0;
  right: 0;
  display: block; }
  @media screen and (max-width: 1340px) {
    .section-head--image-author {
      max-width: 944px; } }
  @media screen and (max-width: 974px) {
    .section-head--image-author {
      margin: 0 2%; } }

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative; }

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block; }

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  transition: height .5s ease-in-out; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease; }

.owl-carousel .owl-item .owl-lazy:not([src]), .owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-carousel {
  margin: 0;
  position: relative;
  z-index: 2;
  overflow: hidden; }
  @media screen and (max-width: 1180px) {
    .owl-carousel {
      margin: 0; } }
  @media screen and (max-width: 768px) {
    .owl-carousel {
      width: 100%;
      max-width: 100%;
      margin: 0 auto 2%;
      overflow: visible; } }
  @media screen and (max-width: 640px) {
    .owl-carousel {
      margin: 0% auto 2%; } }

.owl-item .owl-img {
  position: relative; }

.owl-item .item img {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.owl-item.center {
  z-index: 3; }

.owl-stage-outer {
  overflow: visible !important;
  margin-left: -122px; }
  .owl-stage-outer:after {
    display: block;
    content: '';
    position: absolute;
    width: 25%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
    top: 0;
    left: 75%;
    bottom: 0;
    z-index: 2; }
    @media screen and (max-width: 768px) {
      .owl-stage-outer:after {
        content: none; } }
  @media screen and (max-width: 1340px) {
    .owl-stage-outer {
      margin-left: -100px; } }
  @media screen and (max-width: 1050px) {
    .owl-stage-outer {
      margin-left: -102px; } }
  @media screen and (max-width: 980px) {
    .owl-stage-outer {
      margin-left: -12.3%; } }
  @media screen and (max-width: 768px) {
    .owl-stage-outer {
      margin-left: 0; } }

.__owl_portrait_small .owl-stage-outer {
  margin-left: -302px; }
  @media screen and (max-width: 1340px) {
    .__owl_portrait_small .owl-stage-outer {
      margin-left: -241px; } }
  @media screen and (max-width: 1050px) {
    .__owl_portrait_small .owl-stage-outer {
      margin-left: -231px; } }
  @media screen and (max-width: 980px) {
    .__owl_portrait_small .owl-stage-outer {
      margin-left: -30%; } }
  @media screen and (max-width: 768px) {
    .__owl_portrait_small .owl-stage-outer {
      margin-left: 0; } }

.__owl_portrait_small .owl-desc {
  width: 180%; }
  @media screen and (max-width: 768px) {
    .__owl_portrait_small .owl-desc {
      width: 100%; } }

.__owl_portrait_small .owl-item {
  opacity: 1; }

.__owl_portrait_small .owl-nav {
  width: 86.3%;
  height: 0;
  left: 0;
  right: 0;
  top: 316px; }

.owl-dots {
  display: none; }

.owl-desc {
  opacity: 0;
  width: 100%;
  max-height: 0px;
  padding: 10px 0px; }
  @media screen and (max-width: 768px) {
    .owl-desc {
      opacity: 1;
      width: 100%;
      max-height: none; } }
  .owl-desc small {
    font-size: 100%;
    font-size: 15px;
    color: #333;
    line-height: 1.4; }
    @media screen and (max-width: 1180px) {
      .owl-desc small {
        font-size: 89%; } }
  .owl-desc h4 {
    font-size: 18px;
    font-family: "soleil", sans-serif;
    font-weight: 700;
    margin-bottom: .6%; }
    @media screen and (max-width: 768px) {
      .owl-desc h4 {
        font-size: 15px;
        font-family: "Playfair Display", serif;
        font-weight: 900; } }
    .owl-desc h4 strong {
      font-family: "Playfair Display", serif;
      color: #d6b5ab;
      font-size: 24px; }
      @media screen and (max-width: 768px) {
        .owl-desc h4 strong {
          font-size: 15px;
          font-weight: 900; } }
  .owl-desc strong {
    color: #f6f2f1;
    margin-right: 7px; }

.owl-item {
  opacity: 0;
  transition: opacity 220ms ease; }
  .owl-item.active:not(.center) {
    opacity: .5; }
  .owl-item.center, .owl-item.active {
    opacity: 1;
    transition: opacity 220ms ease; }
  .owl-item:not(.active) + .active {
    opacity: 1;
    transition: opacity 220ms ease; }
  .owl-item.active + active {
    opacity: .5; }
  @media screen and (max-width: 768px) {
    .owl-item {
      padding-bottom: 2%; } }

@media screen and (max-width: 768px) {
  .owl-item.cloned {
    display: none; } }

@media screen and (max-width: 768px) {
  .owl-stage {
    transform: none !important;
    width: 100% !important; }
    .owl-stage .owl-item {
      opacity: 1; } }

.owl-nav {
  position: absolute;
  width: 76%;
  height: 0;
  left: 0;
  right: 0;
  top: 236px; }
  @media screen and (max-width: 1340px) {
    .owl-nav {
      top: 188px; } }
  @media screen and (max-width: 930px) {
    .owl-nav {
      top: 19vw; } }
  @media screen and (max-width: 768px) {
    .owl-nav {
      margin-left: 0;
      width: 100%;
      top: 30vw;
      display: none; } }

.__owl_portrait .owl-nav {
  top: 551px; }
  @media screen and (max-width: 1340px) {
    .__owl_portrait .owl-nav {
      top: 441px; } }
  @media screen and (max-width: 930px) {
    .__owl_portrait .owl-nav {
      top: 43vw; } }

.owl-prev {
  display: none;
  position: absolute;
  left: -30px;
  top: 0;
  transform: translateY(-50%);
  width: 58px;
  height: 58px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  background-color: #fff;
  border: 0;
  outline: 0; }
  .owl-prev span {
    text-indent: -999px;
    overflow: hidden;
    width: 14px;
    height: 14px;
    border: 2px solid #000;
    border-bottom: 0;
    border-left: 0;
    position: absolute;
    transform: rotate(225deg);
    top: 21px;
    left: 24px; }
  @media screen and (max-width: 768px) {
    .owl-prev {
      display: block;
      transform: scale(0.7);
      margin-top: -20px; } }
  .owl-prev:hover {
    background-color: #f6f2f1; }

.owl-next {
  position: absolute;
  right: -30px;
  top: 0;
  transform: translateY(-50%);
  width: 58px;
  height: 58px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  background-color: #fff;
  border: 0;
  outline: 0; }
  .owl-next span {
    text-indent: -999px;
    overflow: hidden;
    width: 14px;
    height: 14px;
    border: 2px solid #000;
    border-bottom: 0;
    border-left: 0;
    position: absolute;
    transform: rotate(45deg);
    top: 21px;
    left: 18px; }
  @media screen and (max-width: 768px) {
    .owl-next {
      display: block;
      transform: scale(0.7);
      margin-top: -20px; } }
  .owl-next:hover {
    background-color: #f6f2f1; }

.center .owl-desc {
  opacity: 1;
  transition: opacity 400ms ease 200ms;
  position: relative;
  padding: 10px 0px;
  line-height: 1.4;
  max-height: 500px; }

.owl--img-auth {
  font-size: 14px; }

.owl-carousel.__owl_ascetic {
  overflow: visible; }
  .owl-carousel.__owl_ascetic .owl-stage-outer {
    margin-left: 0;
    height: 600px;
    overflow: hidden !important; }
    @media screen and (max-width: 1340px) {
      .owl-carousel.__owl_ascetic .owl-stage-outer {
        height: 480px; } }
    @media screen and (max-width: 974px) {
      .owl-carousel.__owl_ascetic .owl-stage-outer {
        height: 52.445098vw; } }
    @media screen and (max-width: 768px) {
      .owl-carousel.__owl_ascetic .owl-stage-outer {
        height: auto; } }
  .owl-carousel.__owl_ascetic .owl-nav {
    width: 100%;
    height: 0;
    top: 50%; }
  .owl-carousel.__owl_ascetic .owl-prev {
    display: block; }
  .owl-carousel.__owl_ascetic .owl-stage-outer:after {
    content: none; }
  .owl-carousel.__owl_ascetic .owl-item {
    opacity: 1;
    height: 600px;
    overflow: hidden; }
    @media screen and (max-width: 1340px) {
      .owl-carousel.__owl_ascetic .owl-item {
        height: 480px; } }
    @media screen and (max-width: 974px) {
      .owl-carousel.__owl_ascetic .owl-item {
        height: 52.445098vw; } }
    @media screen and (max-width: 768px) {
      .owl-carousel.__owl_ascetic .owl-item {
        width: 100% !important;
        height: auto; } }
  .owl-carousel.__owl_ascetic .owl-item img {
    display: block;
    max-height: 100%;
    height: auto;
    width: auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
    @media screen and (max-width: 768px) {
      .owl-carousel.__owl_ascetic .owl-item img {
        width: 100%;
        height: auto;
        transform: none;
        left: 0;
        position: relative; } }

article a.interrelated {
  background-color: #f4f4f4;
  padding: 20px 50px;
  margin: 2% 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative; }
  @media screen and (max-width: 768px) {
    article a.interrelated {
      padding: 5% 0 5%; }
      article a.interrelated:after {
        position: absolute;
        content: '';
        width: 50%;
        height: 100%;
        top: 0;
        left: -20%;
        background-color: #f4f4f4;
        z-index: 0; }
      article a.interrelated:before {
        position: absolute;
        content: '';
        width: 50%;
        height: 100%;
        top: 0;
        right: -20%;
        background-color: #f4f4f4;
        z-index: 0; } }
  article a.interrelated:hover {
    text-decoration: none; }
  article a.interrelated:after {
    display: block;
    clear: both;
    content: ''; }
  article a.interrelated > div {
    display: inline-block;
    order: 0;
    width: 65%;
    position: relative;
    z-index: 1; }
    @media screen and (max-width: 768px) {
      article a.interrelated > div {
        width: 100%; } }

.interrelated .interrelated--foto-wrapper {
  width: 28.47%;
  order: 1; }
  @media screen and (max-width: 768px) {
    .interrelated .interrelated--foto-wrapper {
      width: 192px;
      margin: 4% auto 0;
      position: relative; }
      .interrelated .interrelated--foto-wrapper:before {
        display: block;
        position: absolute;
        content: '';
        background-color: #eeded9;
        border-radius: 50%;
        width: 173px;
        height: 175px;
        top: 10px;
        left: -20px; } }
  @media screen and (max-width: 640px) {
    .interrelated .interrelated--foto-wrapper {
      margin: 6% auto 3%; } }

.interrelated--hd {
  font-size: 16px;
  color: #333; }
  @media screen and (max-width: 768px) {
    .interrelated--hd {
      margin-top: 1%;
      margin-bottom: 1%; } }

.interrelated--txt {
  font-family: "Playfair Display", serif;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.42;
  color: #3e3e3e; }
  @media screen and (max-width: 768px) {
    .interrelated--txt {
      width: 100%;
      font-size: 18px; } }

.interrelated--img {
  display: block;
  vertical-align: middle;
  border-radius: 50%;
  order: 1;
  width: 100%;
  position: relative;
  z-index: 1; }

.teasers--wrapper {
  background-color: #fff;
  position: relative;
  max-width: 1180px;
  margin: 0 auto;
  z-index: 1; }
  @media screen and (max-width: 1340px) {
    .teasers--wrapper {
      max-width: 944px; } }
  @media screen and (max-width: 974px) {
    .teasers--wrapper {
      margin: 0 2%; } }

.teaser {
  padding: 3% 0; }
  .teaser .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .teaser .wrapper:after {
      content: none; }
    @media screen and (max-width: 640px) {
      .teaser .wrapper {
        padding-bottom: 3%;
        flex-wrap: wrap;
        padding-top: .5%; } }
  .teaser .article--source {
    margin-bottom: 1.6%;
    position: relative;
    z-index: 2; }
    .teaser .article--source img {
      width: 30px;
      height: 30px;
      filter: grayscale(1); }
    @media screen and (max-width: 640px) {
      .teaser .article--source {
        background-color: #f6f2f1;
        margin-bottom: 0;
        padding: 3% 6.75% .5% 6.75%; } }

.teaser--img {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  border-radius: 50%;
  max-width: 100%;
  transform: scale(1) translate3d(0, 0, 0);
  transition: transform 620ms ease;
  will-change: transform;
  overflow: hidden; }

.teaser--article {
  width: 62.204%; }
  @media screen and (max-width: 640px) {
    .teaser--article {
      width: 100%;
      order: 1;
      margin-top: 2%; } }
  .teaser--article > a:after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; }

.teaser--img-wrapper {
  width: 31.018%;
  padding-top: 31.018%;
  position: relative;
  border-radius: 50%; }
  @media screen and (max-width: 640px) {
    .teaser--img-wrapper {
      order: 0;
      width: 192px;
      padding-top: 192px;
      border-radius: 50%; } }
  .teaser--img-wrapper:before {
    position: absolute;
    width: 96%;
    height: 96%;
    content: "";
    background-color: #eeded9;
    left: -12%;
    top: 2%;
    border-radius: 50%; }
  .teaser--img-wrapper:first-of-type {
    margin-right: 0;
    margin-left: 4%; }
    @media screen and (max-width: 640px) {
      .teaser--img-wrapper:first-of-type {
        margin: 0 auto; } }
  .teaser--img-wrapper:last-of-type {
    margin-right: 4%;
    margin-left: 0; }
    @media screen and (max-width: 640px) {
      .teaser--img-wrapper:last-of-type {
        margin: 0 auto;
        order: 0; } }
    .teaser--img-wrapper:last-of-type:before {
      right: -12%;
      left: auto; }
  .teaser--img-wrapper > div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    overflow: hidden;
    transform: translate3d(0px, 0px, 0px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden; }

.teaser:hover .teaser--img {
  transform: scale(1.05);
  will-change: transform; }
  @media screen and (max-width: 640px) {
    .teaser:hover .teaser--img {
      transform: scale(1.05);
      overflow: hidden; } }

.teaser--title {
  font-family: "Playfair Display", serif;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.23;
  background-color: #eeded9;
  padding: 1.1% 3.7% 1.4% 2.5%;
  margin-bottom: 2%;
  position: relative;
  z-index: 2;
  transition: all 220ms ease; }
  @media screen and (max-width: 1080px) {
    .teaser--title {
      font-size: 34px; } }
  @media screen and (max-width: 768px) {
    .teaser--title {
      font-size: 18px; } }
  @media screen and (max-width: 640px) {
    .teaser--title {
      background-color: #f6f2f1;
      padding: 2% 6.75% 3.5% 6.75%; } }

a:hover .teaser--title {
  background-color: #f6f2f1; }

.teaser--lead {
  font-size: 22px;
  line-height: 1.35;
  font-weight: 300; }
  @media screen and (max-width: 1080px) {
    .teaser--lead {
      font-size: 20px; } }
  @media screen and (max-width: 768px) {
    .teaser--lead {
      font-size: 16px; } }
  @media screen and (max-width: 640px) {
    .teaser--lead {
      padding: 0 6.75%; } }

section.section {
  padding: 2% 0; }

.section--title {
  font-weight: 900;
  padding-bottom: 2%;
  line-height: 1.33;
  font-size: 24px;
  padding: 2% 0;
  font-family: "Playfair Display", serif;
  font-weight: 900;
  position: relative;
  z-index: 1; }
  @media screen and (max-width: 768px) {
    .section--title {
      font-size: 16px; } }
  .section--title span {
    color: #dcb8a4; }

.section--video {
  margin: 3% 0;
  padding: 2% 0;
  background-color: #f4f4f4; }
  @media screen and (max-width: 974px) {
    .section--video {
      padding: 2%; } }
  @media screen and (max-width: 640px) {
    .section--video {
      padding: 3% 0 5%; } }

.section--video-hd {
  font-size: 40px;
  font-family: "Playfair Display", serif;
  font-weight: 900;
  line-height: 1.31;
  padding-bottom: 2.5%; }
  @media screen and (max-width: 768px) {
    .section--video-hd {
      font-size: 16px; } }
  @media screen and (max-width: 640px) {
    .section--video-hd {
      padding: 2% 6.75% 4.5%; } }
  .section--video-hd small {
    color: #dcb8a4;
    display: block; }

.section.slider {
  position: relative; }
  @media screen and (max-width: 768px) {
    .section.slider {
      margin: 4% 0;
      background-color: #f6f2f1;
      position: relative; }
      .section.slider:before {
        position: absolute;
        content: '';
        width: 130%;
        height: 100%;
        top: 0;
        right: -20%;
        background-color: #f4f4f4;
        z-index: 0; } }
  @media screen and (max-width: 640px) {
    .section.slider:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: -20%;
      z-index: 3;
      content: '';
      width: 130%;
      position: absolute;
      height: 50vw;
      background: linear-gradient(to bottom, transparent 0%, #f4f4f4 60%); } }
  @media screen and (max-width: 640px) {
    .section.slider .owl-carousel {
      max-height: 120vw;
      overflow: hidden; } }
  .section.slider.__seeMore:after {
    display: none; }
  @media screen and (max-width: 640px) {
    .section.slider.__seeMore .owl-carousel {
      max-height: 12200px;
      transition: all 220ms ease; } }

@media screen and (max-width: 974px) {
  .section .wrapper {
    margin: 0 0; } }

.section-slider--more {
  display: none;
  width: 207px;
  height: 43px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  font-size: 15px;
  line-height: 43px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 4;
  font-weight: 600;
  margin-bottom: 4%; }
  @media screen and (max-width: 640px) {
    .section-slider--more {
      display: block;
      cursor: pointer; } }

.section--teaser {
  margin-bottom: 2%;
  box-shadow: 0 2px 15px 8px rgba(0, 0, 0, 0.03); }
  .section--teaser article a {
    color: #3e3e3e;
    display: inline-block; }
    .section--teaser article a:hover {
      text-decoration: none; }
  .section--teaser .article {
    background-color: #f6f2f1;
    padding: 1.6% 6.75%;
    box-shadow: 0 2px 15px 8px rgba(0, 0, 0, 0.4); }
    @media screen and (max-width: 974px) {
      .section--teaser .article {
        padding: 2.2% 6.75%; } }
  .section--teaser .article--title {
    border-bottom: 1px solid #979797;
    padding-top: 0; }
  .section--teaser .article--lead {
    border-color: #979797;
    margin-bottom: 0; }
    @media screen and (max-width: 640px) {
      .section--teaser .article--lead {
        font-size: 14px;
        line-height: 1.43; } }
  .section--teaser .article--source {
    margin-bottom: 1.6%; }
    .section--teaser .article--source img {
      width: 30px;
      filter: grayscale(1); }
      @media screen and (max-width: 640px) {
        .section--teaser .article--source img {
          display: none; } }
    @media screen and (max-width: 640px) {
      .section--teaser .article--source {
        padding: 2% 0; } }
    @media screen and (max-width: 768px) {
      .section--teaser .article--source a {
        display: flex; } }
  @media screen and (max-width: 640px) {
    .section--teaser {
      margin-bottom: 9%; } }

.section section--teaser a .article--more {
  color: #dcb8a4; }

.author-box {
  background-color: #f6f2f1;
  margin-bottom: 3%; }
  .author-box .wrapper {
    align-content: center;
    padding: 50px 0; }
    @media screen and (max-width: 640px) {
      .author-box .wrapper {
        display: block;
        padding: 40px 0; } }

.author-box--app h1 {
  font-size: 38px;
  line-height: normal;
  font-weight: 900;
  font-family: "Playfair Display", serif;
  padding: 0 0 1%; }
  @media screen and (max-width: 1340px) {
    .author-box--app h1 {
      font-size: 30px; } }
  @media screen and (max-width: 640px) {
    .author-box--app h1 {
      font-size: 26px; } }

.author-box--img-wrapper {
  width: 192px;
  height: 192px;
  border-radius: 50%;
  overflow: hidden;
  float: left; }
  .author-box--img-wrapper img {
    max-width: 100%;
    max-height: 100%; }
  @media screen and (max-width: 640px) {
    .author-box--img-wrapper {
      float: none;
      margin: 0 auto; } }

.author-box--app {
  font-weight: 300;
  font-size: 18px;
  margin-left: 30px;
  margin-left: 230px; }
  @media screen and (max-width: 640px) {
    .author-box--app {
      width: 90%;
      margin: 3% auto 0 auto;
      text-align: center;
      font-size: 16px; } }
  .author-box--app:nth-child(1) {
    margin-left: 0px; }
    @media screen and (max-width: 640px) {
      .author-box--app:nth-child(1) {
        margin-left: auto;
        margin-top: 0; } }

.author-box--mail {
  font-weight: 500;
  border-top: solid 1px rgba(238, 222, 217, 0.65);
  margin-top: 19px;
  padding-top: 19px;
  padding-left: 0; }
  .author-box--mail a {
    margin-right: 20px;
    white-space: nowrap; }
    @media screen and (max-width: 640px) {
      .author-box--mail a {
        font-size: 14px; } }

.author-box--mail-ico:before {
  content: url(images/mail.svg);
  vertical-align: middle;
  margin-right: 10px;
  top: -4px;
  position: relative; }
  @media screen and (max-width: 640px) {
    .author-box--mail-ico:before {
      top: -2px; } }

.author-box--office-ico:before {
  content: url(images/instagram.svg);
  vertical-align: middle;
  margin-right: 10px;
  top: -2px;
  position: relative; }
  @media screen and (max-width: 640px) {
    .author-box--office-ico:before {
      top: 0px; } }

.author--list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (max-width: 938px) {
    .author--list {
      justify-content: space-around; } }
  .author--list .global--hd {
    margin-top: -2%;
    margin-bottom: 1%; }

.author--item {
  width: 47.8%;
  position: relative;
  border: 2px solid #eeded9;
  margin-bottom: 3%;
  padding: 22px;
  box-sizing: border-box; }
  @media screen and (max-width: 938px) {
    .author--item {
      max-width: 400px; } }
  @media screen and (max-width: 668px) {
    .author--item {
      width: 100%;
      margin-bottom: 5%; } }

.author--img-wrapper {
  width: 119px;
  height: 119px;
  position: relative;
  border-radius: 50%;
  float: left;
  margin-right: 28px; }
  .author--img-wrapper img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%; }
  @media screen and (max-width: 1080px) {
    .author--img-wrapper {
      margin-bottom: 15px; } }
  @media screen and (max-width: 938px) {
    .author--img-wrapper {
      float: none;
      margin: 0 auto 15px; } }

.author--data {
  margin-left: 130px; }
  @media screen and (max-width: 938px) {
    .author--data {
      margin-left: 0; } }

.author--name {
  font-size: 20px;
  font-family: "Playfair Display", serif;
  font-weight: 900; }

.author--function {
  color: #727272;
  font-size: 14px; }

.author--data .author-box--mail {
  font-size: 14px;
  color: #3e3e3e;
  border: 0;
  padding-top: 12px;
  margin-top: 0; }
  .author--data .author-box--mail a {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 300; }
  .author--data .author-box--mail .author-box--mail-ico:before {
    top: -2px; }
  .author--data .author-box--mail .author-box--office-ico:before {
    top: 0px; }

.author-more {
  position: absolute;
  right: 0;
  bottom: 0;
  line-height: 32px;
  height: 32px;
  color: #3e3e3e;
  font-size: 14px;
  padding: 0 14px 0 17px;
  background-color: #eeded9; }
  .author-more:before {
    content: 'Więcej'; }
  .author-more:after {
    display: inline-block;
    content: '';
    width: 8px;
    height: 8px;
    vertical-align: middle;
    margin-left: 7px;
    border-right: 1px solid #3c3c3c;
    border-bottom: 1px solid #3c3c3c;
    transform: rotate(-45deg); }

.socials--wrapper {
  overflow: hidden;
  position: relative;
  z-index: 2; }

.__js_socialsSticky {
  position: sticky;
  top: 0;
  z-index: 7; }
  @media screen and (max-width: 1140px) {
    .__js_socialsSticky {
      display: none; } }
  @media only screen and (max-height: 580px) {
    .__js_socialsSticky {
      display: none; } }

.__js_socialsSticky .article--socials {
  position: absolute;
  left: 0px;
  top: 541px;
  left: 50vw;
  transform: translateX(-670px);
  padding: 5px;
  width: 80px;
  text-align: center; }
  @media screen and (max-width: 1340px) {
    .__js_socialsSticky .article--socials {
      transform: translateX(-570px); } }
  @media screen and (max-width: 1920px) {
    .__js_socialsSticky .article--socials {
      top: calc(50vh);
      margin-top: -150px; } }

.article--socials {
  font-size: 14px;
  color: #7f7f7f; }
  @media screen and (max-width: 640px) {
    .article--socials {
      font-size: 14px; } }

.socials--item {
  display: block; }

.article--socials [data-category], .socials--trigger {
  display: block;
  width: 58px;
  height: 58px;
  margin: 0;
  margin-bottom: 15px;
  margin-top: 5px;
  cursor: pointer;
  margin: 5px auto 15px auto; }

article .article--socials {
  padding: 3% 0; }

article .article--socials [data-category] {
  display: inline-block;
  width: 58px;
  height: 58px;
  margin: 0;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 15px; }
  @media screen and (max-width: 640px) {
    article .article--socials [data-category] {
      width: 48px;
      height: 48px;
      background-size: contain; } }

article .socials--item + .socials--item {
  margin-left: -8px; }

article .socials--item {
  display: inline-block; }

article .socials--item {
  opacity: 0;
  left: -15px;
  position: relative; }

article .article--socials.__js_onScreen .socials--item:nth-of-type(1) {
  opacity: 1;
  transition: all 320ms ease 150ms;
  left: 0;
  display: inline-block; }

article .article--socials.__js_onScreen .socials--item:nth-of-type(2) {
  opacity: 1;
  left: 0;
  transition: all 320ms ease 450ms;
  display: inline-block; }

article .article--socials.__js_onScreen .socials--item:nth-of-type(3) {
  opacity: 1;
  left: 0;
  transition: all 320ms ease 750ms;
  display: inline-block; }
  @media screen and (max-width: 640px) {
    article .article--socials.__js_onScreen .socials--item:nth-of-type(3) {
      display: none; } }

.socials--list {
  max-height: 0;
  opacity: 0;
  overflow: hidden; }

article .socials--list {
  max-height: none;
  opacity: 1;
  overflow: visible; }

.socials--trigger {
  background: url(images/share.png);
  margin-bottom: 10px; }

.socials--trigger:hover, .socials:hover .socials--trigger {
  background: url(images/share-h.png); }

.socials--trigger:hover + .socials--list, .socials:hover .socials--list {
  max-height: 300px;
  opacity: 1;
  transition: all 220ms ease;
  overflow: visible; }

.article--socials [data-category="facebook"] {
  background: url(images/share-fb.png);
  background-size: contain; }

.article--socials [data-category="facebook"]:hover {
  background: url(images/share-fb-h.png);
  background-size: contain; }

.article--socials [data-category="twitter"] {
  background: url(images/share-tw.png);
  background-size: contain; }

.article--socials [data-category="twitter"]:hover {
  background: url(images/share-tw-h.png);
  background-size: contain; }

.article--socials [data-category="link"] {
  background: url(images/share-link.png);
  background-size: contain; }

.article--socials [data-category="link"]:hover {
  background: url(images/share-link-h.png);
  background-size: contain; }

.__js_socials__input {
  display: inline-block;
  position: absolute;
  z-index: 12;
  padding: 12px 15px;
  background-color: #fff;
  left: 0px;
  border-radius: 30px;
  border: 1px solid #ccc;
  font-size: 12px; }
  .__js_socials__input:before {
    display: block;
    content: 'skopiowano:';
    font-size: 11px; }

[id^="dynacrems-adv-"] {
  min-height: 380px; }
  @media screen and (max-width: 1180px) {
    [id^="dynacrems-adv-"] {
      min-height: 370px; } }

.cdc {
  margin: 3% auto; }

.cdc--wrapper {
  position: relative;
  min-height: 94.4%; }

.cdc--products {
  line-height: 1.3;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.cdc--product {
  border: 4px solid #ccc;
  width: calc(100% / 2 - 14px);
  box-sizing: border-box;
  position: relative; }

.cdc--product-inner {
  height: 100%;
  padding: 24px 0 50px; }

.cdc--photo-link {
  display: block; }

.cdc--photo-wrapper {
  text-align: center;
  position: relative;
  width: 55%;
  /* proporcje zdjęcia: 300x180 */
  overflow: hidden;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 588px) {
    .cdc--photo-wrapper {
      float: none;
      width: 100%; } }

.cdc--photo {
  max-width: 100%;
  display: block;
  left: 0;
  right: 0;
  margin: 0px auto;
  overflow: hidden; }
  @media screen and (max-width: 588px) {
    .cdc--photo {
      margin-bottom: 10px; } }

.cdc--details {
  display: flex;
  flex-wrap: wrap;
  padding: 0 14px;
  box-sizing: border-box; }
  @media screen and (max-width: 588px) {
    .cdc--details {
      padding: 0 5px; } }

.cdc--title {
  text-align: center;
  width: 100%;
  padding: 10px 0;
  font-weight: 600;
  font-size: 20px; }

article .cdc--title a {
  margin: 3px 0 9px;
  font-weight: 600;
  color: #333;
  margin-bottom: 2%;
  width: 100%;
  font-family: "soleil", sans-serif;
  text-align: center; }
  @media screen and (max-width: 588px) {
    article .cdc--title a {
      float: none;
      width: 100%;
      text-align: center;
      margin-bottom: 0%; } }

.cdc--subtitle {
  text-align: center; }

.cdc--description {
  font-size: 1.8rem;
  color: #333;
  font-weight: 400;
  overflow: visible;
  line-height: 1.4;
  text-align: center;
  width: 100%;
  box-sizing: border-box; }
  @media screen and (max-width: 1180px) {
    .cdc--description {
      max-width: 90%;
      padding-right: 10px;
      box-sizing: border-box; } }
  @media screen and (max-width: 588px) {
    .cdc--description {
      float: none;
      width: 100%;
      max-width: 100%;
      padding-right: 0;
      text-align: center; } }

.cdc--price {
  width: 100%;
  font-weight: 700;
  padding: 4% 0;
  font-size: 3rem;
  color: #000; }

.cdc--old-price {
  text-decoration: line-through; }

.cdc--current-price {
  color: #ff0000a1;
  text-decoration: none;
  margin-left: 20px; }

.cdc--external {
  display: block;
  width: 100%;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  color: #939393;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
  padding: 6% 0 6%;
  margin: 0 auto;
  font-weight: 400;
  letter-spacing: 0.09rem; }

.cdc--external-img {
  width: 41%;
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle; }

.cdc--button {
  position: absolute;
  color: #fff;
  line-height: 4rem;
  font-size: 1.4rem;
  padding: 0 40px;
  height: 40px;
  letter-spacing: 0.2rem;
  font-weight: 700;
  box-sizing: border-box;
  z-index: 3;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 3;
  max-width: 160px;
  bottom: -20px; }
  .cdc--button:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: inline-block;
    color: #fff;
    background: #f6f2f1;
    padding: 0 40px;
    height: 40px;
    border-radius: 25px;
    z-index: -1; }
  .cdc--button:hover:after {
    background: #eeded9; }

.cdc--button span {
  display: block; }
  @media screen and (max-width: 588px) {
    .cdc--button span {
      margin: 0 auto;
      text-align: center; } }

.cdc--button:hover span:after {
  background-color: #eeded9; }

.cdc--product.__sold_out {
  position: relative;
  filter: contrast(0.3) brightness(1.6); }

.cdc--product.__sold_out:after {
  display: block;
  content: '';
  overflow: hidden;
  position: absolute;
  background-color: #333;
  opacity: .7;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  font-size: 200%;
  color: #fff;
  padding-top: 49%; }

.cdc--product.__sold_out:before {
  display: block;
  content: 'WYPRZEDANE';
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 49%;
  text-align: center;
  font-size: 200%;
  color: #fff;
  z-index: 2;
  transform: rotate(45deg); }

.main-footer {
  z-index: 2;
  position: relative;
  width: 100%; }
  .main-footer .outer {
    margin: 0px auto;
    width: 100%; }
  .main-footer .wrapper {
    position: relative;
    text-align: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: visible;
    font-size: 14px;
    color: #3e3e3e;
    min-height: 30px;
    padding-left: 10px;
    padding-bottom: 15px; }
    .main-footer .wrapper a {
      margin: 0 10px;
      white-space: nowrap; }
