.teasers--wrapper {
    background-color: #fff;
    position: relative;
    max-width: $articlePoint;
    margin: 0 auto;
    z-index: 1;
    
    @include max-breakpoint ($socialsPoint) {
      max-width: $narrowLayout;
    }
    @include max-breakpoint ($narrowLayout + 30) {
         margin: 0 2%;
    }
    
}
.teaser {
    padding: 3% 0;
    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:after {
            content: none;
        }
        @include max-breakpoint($point3) {
            padding-bottom: 3%;
            flex-wrap: wrap;
            padding-top: .5%;
        }
    }
    .article--source {
        margin-bottom: 1.6%;
        position: relative;
        z-index: 2;
        img {
            width: 30px;
            height: 30px;
            filter: grayscale(1);
        }
        @include max-breakpoint ($point3) {
            background-color: $main-color;
            margin-bottom: 0;
            padding: 3% 6.75% .5% 6.75%;
        }
    }
    
    
}
.teaser--img {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    border-radius: 50%;
    max-width: 100%;
    transform: scale(1) translate3d(0, 0, 0);
    transition: transform 620ms ease;
    will-change: transform;
    overflow: hidden;
}
.teaser--article {
    width: 62.204%;
    @include max-breakpoint($point3) {
        width: 100%;
        order: 1;
        margin-top: 2%;
    }
    > a:after {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
}
.teaser--img-wrapper {
    width: 31.018%;
    padding-top: 31.018%;
    position: relative;
    border-radius: 50%;
    
    @include max-breakpoint($point3) {
        order: 0;
        width: 192px;
        padding-top: 192px;
        border-radius: 50%;
    }
    &:before {
       position: absolute;
        width: 96%;
        height: 96%;
        content: "";
        background-color: $domain-color;
        left: -12%;
        top: 2%;
        border-radius: 50%;
    }
    &:first-of-type {
        margin-right: 0;
        margin-left: 4%;
        @include max-breakpoint($point3) {
            margin: 0 auto;
        }
    }
    &:last-of-type {
        margin-right: 4%;
        margin-left: 0;
        @include max-breakpoint($point3) {
            margin: 0 auto;
            order: 0;
        }
        &:before {
            right: -12%;
            left: auto;
        }
    }
    > div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        overflow: hidden;
        transform: translate3d(0px, 0px, 0px);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden
    }

}
.teaser:hover .teaser--img {
    transform: scale(1.05);
    will-change: transform;
    
    @include max-breakpoint ($point3) {
        transform: scale(1.05);
        overflow: hidden;
    }
}
.teaser--title {
    font-family: $font-headers;
    font-size: 40px;
    font-weight: 900;
    line-height: 1.23;
    background-color: $domain-color;
    padding: 1.1% 3.7% 1.4% 2.5%;
    margin-bottom: 2%;
    position: relative;
    z-index: 2;
    transition: all 220ms ease;
    @include max-breakpoint ($point1) {
        font-size: 34px;
    }
    @include max-breakpoint ($point2) {
        font-size: 18px;
    }
    @include max-breakpoint ($point3) {
        background-color: $main-color;
        padding: 2% 6.75% 3.5% 6.75%;
    }
}
a:hover {
    .teaser--title {
        background-color: $main-color;
    }
}
.teaser--lead {
    font-size: 22px;
    line-height: 1.35;
    font-weight: 300;
    @include max-breakpoint ($point1) {
        font-size: 20px;
    }
    @include max-breakpoint ($point2) {
        font-size: 16px;
    }
    @include max-breakpoint ($point3) {
        padding: 0 6.75%;
    }
}