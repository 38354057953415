.socials--wrapper {
    overflow: hidden;
    position: relative;
    z-index: 2;
}
.socials {
    
}

.__js_socialsSticky {
    position: sticky;
    top:0;
    z-index: 7;
    @include max-breakpoint($socialsPoint2 + 110) {
        display: none;
    }
    @media only screen and (max-height: 580px) {
        display: none;
    }
}

.__js_socialsSticky .article--socials {
    position:absolute;
    left: 0px;
    top: 541px;
    left: 50vw;
    transform: translateX(-670px);
    padding: 5px;
    width: 80px;
    text-align: center;
    @include max-breakpoint($socialsPoint) {
        transform: translateX(-570px);
    }
    @include max-breakpoint (1920px) {
        //top: 26.63vw;
        top: calc(50vh);
        margin-top: -150px;
    }
}

.article--socials {
    font-size: 14px;
    color: #7f7f7f;
    @include max-breakpoint ($point3) {
        font-size: 14px;
    }
}
.socials--item {
    display: block;
}
.article--socials [data-category], .socials--trigger {
    display: block;
    width: 58px;
    height: 58px;
    margin: 0;
    margin-bottom: 15px;
    margin-top: 5px;
    cursor: pointer;
    margin: 5px auto 15px auto;
}


article .article--socials {
    padding: 3% 0;
}
article .article--socials [data-category] {
    display: inline-block;
    width: 58px;
    height: 58px;
    margin: 0;
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 15px;
    @include max-breakpoint ($point3) {
        width: 48px;
        height: 48px;
        background-size: contain;
    }
    
}

article .socials--item + .socials--item {
    margin-left: -8px;
} 
article .socials--item {
    display: inline-block;
}
article .socials--item {
    opacity: 0;
    left: -15px;
    position: relative;
}


article .article--socials.__js_onScreen .socials--item:nth-of-type(1) {
           opacity: 1;
           transition: all 320ms ease 150ms;
           left: 0;
           display: inline-block;
}
article .article--socials.__js_onScreen .socials--item:nth-of-type(2) {
            opacity: 1;
           left: 0;
            transition: all 320ms ease 450ms;        
            display: inline-block;
}
article .article--socials.__js_onScreen .socials--item:nth-of-type(3) {
    opacity: 1;
    left: 0;
    transition: all 320ms ease 750ms;
    display: inline-block;
    @include max-breakpoint ($point3) {
        display: none;
    }
}

.socials--list {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
}
article .socials--list {
    max-height: none;
    opacity: 1;
    overflow: visible;
}
.socials--trigger {
    background: url(images/share.png);
    margin-bottom: 10px;
}
.socials--trigger:hover, .socials:hover .socials--trigger {
    background: url(images/share-h.png);
}
.socials--trigger:hover + .socials--list, .socials:hover .socials--list {
    max-height: 300px;
    opacity: 1;
    transition: all 220ms ease;
    overflow: visible;
}

.article--socials [data-category="facebook"] {
    background: url(images/share-fb.png);
    background-size: contain;
}
.article--socials [data-category="facebook"]:hover {
    background: url(images/share-fb-h.png);
    background-size: contain;
}
.article--socials [data-category="twitter"] {
    background: url(images/share-tw.png);
    background-size: contain;
}
.article--socials [data-category="twitter"]:hover {
    background: url(images/share-tw-h.png);    
    background-size: contain;
}
.article--socials [data-category="link"] {
    background: url(images/share-link.png);
    background-size: contain;
}
.article--socials [data-category="link"]:hover {
    background: url(images/share-link-h.png);
    background-size: contain;
}
.__js_socials__input {
    display: inline-block;
    position: absolute;
    z-index: 12;
    padding: 12px 15px;
    background-color: #fff;
    left: 0px;
    border-radius: 30px;
    border: 1px solid #ccc;
    font-size: 12px;
    &:before {
        display: block;
        content: 'skopiowano:';
        font-size: 11px;
    }
}